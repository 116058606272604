import { AspectRatios, Block, Grid, ImageInput } from '@codepoint-pt/xela';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import IntlSelectInput from '../../../components/inputs/IntlSelectInput';
import TranslatableFields from '../../../components/inputs/TranslatableFields';
import { Trail } from '../../../models/Trail';

interface Props {
  trails: Trail[];
  subValidate: (fields: string[]) => boolean;
}

const InformationTab = ({ subValidate, trails }: Props) => {
  const { t } = useTranslation();

  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Grid>
        <Grid.Col xs={6} offset={3}>
          <Field name="photo">
            {(props) => (
              <ImageInput
                {...props}
                label={t('PHOTO')}
                ratio={AspectRatios['16:9']}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="trail">
            {(props) => (
              <IntlSelectInput
                {...props}
                label={t('ROUTE')}
                placeholder={t('INPUT_ROUTE_PLACEHOLDER')}
                data={trails}
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12}>
          <TranslatableFields
            subValidate={subValidate}
            fields={[
              {
                label: 'NAME',
                name: 'name',
                type: 'text'
              },
              {
                label: 'DESCRIPTION',
                name: 'description',
                type: 'richtext'
              }
            ]}
          />
        </Grid.Col>
      </Grid>
    </Block>
  );
};

export default InformationTab;
