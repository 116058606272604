import { setIn } from 'final-form';
import { useMemo } from 'react';
import yup, { YupSchema } from '@codepoint-pt/yup-validations';
import { AnyObject } from '../../models/Generic';

const validateFormValues = (schema: YupSchema) => async (values: AnyObject) => {
  try {
    await schema.validate(values, { abortEarly: false });
    return {};
  } catch (err) {
    const errors = ((err as yup.ValidationError).inner || []).reduce(
      (formError: AnyObject, innerError: AnyObject) => {
        return setIn(formError, innerError.path || '', innerError.message);
      },
      {}
    );
    return errors;
  }
};

export function validateSchema(schema: YupSchema) {
  return validateFormValues(schema);
}

export function useValidationSchema(schema: YupSchema) {
  return useMemo(() => validateFormValues(schema), [schema]);
}
