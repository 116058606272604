import {
  LoadingOverlay,
  PageHeader,
  toFormData,
  Block
} from '@codepoint-pt/xela';
import yup from '@codepoint-pt/yup-validations';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { withTypes } from 'react-final-form';
import { User } from '../../models/User';
import { BasicForm } from '../../styles/BasicStyles';
import { useContext, useState, useEffect } from 'react';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { UserContext } from '../../Context';
import useFetch from 'use-http';
import UserForm from './UserForm';
import FormPrompt from '../../components/prompt/FormPrompt';
import headerActions from '../../components/header/HeaderActions';

const { Form } = withTypes<User>();

const ManageAdministratorPage = () => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useContext(UserContext);

  const { get, post, put, del } = useFetch('/admins');

  useEffect(() => {
    const init = async () => {
      if (id === user?._id) return navigate('/my-profile');

      if (id) {
        const { data, success } = await get(`/${id}`);
        if (success) setInitialValues(data);
      }
      setLoading(false);
    };
    init();
  }, [id]);

  const onSubmit = async (values: User) => {
    let success = false;
    const payload = toFormData(values);

    if (id) {
      const result = await put(`/${values._id}`, payload);
      success = result.success;
    } else {
      const result = await post(payload);
      success = result.success;
    }

    if (success) navigate('/admins');
  };

  const onDelete = async () => {
    if (id) {
      setLoading(true);
      const { success } = await del(`/${id}`);
      if (success) navigate('/admins');
      else setLoading(false);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={useValidationSchema(
        yup.object({
          name: yup.string().required(),
          email: yup.string().email().required()
        })
      )}
    >
      {({ handleSubmit, submitting, pristine }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <FormPrompt
            when={!pristine && !submitting && !loading}
            message={t('PROMPT_MESSAGE')}
          />
          <PageHeader
            navigate={navigate}
            title={t(id ? 'EDIT_ADMINISTRATOR' : 'ADD_ADMINISTRATOR')}
            onBack={() => navigate('/admins')}
            breadcrumbs={[
              { title: t('ADMINISTRATORS'), href: '/admins' },
              { title: t(id ? 'EDIT' : 'ADD') }
            ]}
            actions={headerActions({
              submitting,
              pristine,
              backLink: '/admins',
              onDelete: id ? onDelete : undefined,
              deleteTitle: 'DELETE_ADMIN',
              deleteMessage: 'DELETE_ADMIN_MESSAGE'
            })}
          />
          <Block
            borderRadius="16px"
            bg="white"
            padding="50px 15px"
            margin="10px 0"
          >
            <UserForm />
          </Block>
        </BasicForm>
      )}
    </Form>
  );
};

export default ManageAdministratorPage;
