import { PrimaryColor, LightPrimary, ScrollBarThumbHover, ScrollBarThumb, ScrollBarTrack } from './Colors';

const Theme = {
  colors: {
    primaryColor: PrimaryColor,
    lightPrimary: LightPrimary,
    scrollBarThumbHover: ScrollBarThumbHover,
    scrollBarThumb: ScrollBarThumb,
    scrollBarTrack: ScrollBarTrack
  }
};

export default Theme;
