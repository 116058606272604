import {
  Block,
  LoadingOverlay,
  PageHeader,
  toFormData
} from '@codepoint-pt/xela';
import yup, {
  isCoordinate,
  isContact,
  isMongoId
} from '@codepoint-pt/yup-validations';
import { useContext, useEffect, useState } from 'react';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import MapInput from '../../components/inputs/MapInput';
import FormPrompt from '../../components/prompt/FormPrompt';
import BaseTabs from '../../components/tabs/BaseTabs';
import { InfoContext } from '../../Context';
import useIntlValidation from '../../hooks/use-validation/use-inlt-validation';
import useSectionValidation from '../../hooks/use-validation/use-section-validation';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { Poi } from '../../models/Poi';
import { BasicForm } from '../../styles/BasicStyles';
import ContactsTab from './tabs/ContactsTab';
import InformationTab from './tabs/InformationTab';
import MultimediaTab from './tabs/MultimediaTab';
import { ValidationErrors } from 'final-form';
import AlertsTab from './tabs/AlertsTab';

const { Form } = withTypes<Poi>();

const ManagePoisPage = () => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { info } = useContext(InfoContext);
  const intlObject = useIntlValidation();

  const { get, post, put, del } = useFetch('/pois');

  useEffect(() => {
    const init = async () => {
      if (id) {
        const { data, success } = await get(`/${id}`);
        if (success)
          setInitialValues({
            ...data,
            category: data?.category?._id,
            municipality: data?.municipality?._id
          });
      }
      setLoading(false);
    };
    init();
  }, [id]);

  const onSubmit = async (values: Poi) => {
    let success = false;
    delete values.alerts;
    const payload = toFormData(values);

    if (id) {
      const result = await put(`/${values._id}`, payload);
      success = result.success;
    } else {
      const result = await post(payload);
      success = result.success;
    }

    if (success) navigate('/pois');
  };

  const onDelete = async () => {
    if (id) {
      setLoading(true);
      const { success } = await del(`/${id}`);
      if (success) navigate('/pois');
      else setLoading(false);
    }
  };

  const defineTabs = (errors: ValidationErrors, submitFailed: boolean) => {
    const tabs = [
      {
        value: 'information',
        label: t('INFORMATION'),
        children: (
          <InformationTab
            categories={info?.categories}
            municipalities={info?.municipalities}
            subValidate={(fields) =>
              useSectionValidation(errors, fields, submitFailed)
            }
          />
        ),
        error: useSectionValidation(
          errors,
          ['name', 'description', 'schedule'],
          submitFailed
        )
      },
      {
        value: 'multimedia',
        label: t('MULTIMEDIA'),
        children: (
          <MultimediaTab
            subValidate={(fields) =>
              useSectionValidation(errors, fields, submitFailed)
            }
          />
        )
      },
      {
        value: 'coordinates',
        label: t('COORDINATES'),
        error: useSectionValidation(errors, ['coordinates'], submitFailed),
        children: (
          <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
            <Field name="coordinates">
              {(props) => (
                <MapInput
                  {...props}
                  label={t('COORDINATES')}
                  latitudeLabel={t('LATITUDE')}
                  latitudePlaceholder={t('INPUT_LATITUDE_PLACEHOLDER')}
                  longitudeLabel={t('LONGITUDE')}
                  longitudePlaceholder={t('INPUT_LONGITUDE_PLACEHOLDER')}
                />
              )}
            </Field>
          </Block>
        )
      },
      {
        value: 'contacts',
        label: t('CONTACTS'),
        children: <ContactsTab />,
        error: useSectionValidation(
          errors,
          ['address', 'contact', 'phone', 'email', 'website'],
          submitFailed
        )
      }
    ];

    if (id) {
      tabs.push({
        value: 'alerts',
        label: t('ALERTS'),
        children: <AlertsTab />
      });
    }

    return tabs;
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={useValidationSchema(
        yup.object({
          name: intlObject({ required: true }).required(),
          description: intlObject({ required: true }).required(),
          category: isMongoId.required(),
          municipality: isMongoId,
          coordinates: yup
            .object({
              latitude: isCoordinate.required(),
              longitude: isCoordinate.required()
            })
            .required(),
          address: yup.string().nullable(),
          contact: yup.string().nullable(),
          phone: isContact,
          email: yup.string().email(),
          website: yup.string()
        })
      )}
    >
      {({ handleSubmit, submitting, pristine, errors, submitFailed }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <FormPrompt
            when={!pristine && !submitting && !loading}
            message={t('PROMPT_MESSAGE')}
          />
          <PageHeader
            navigate={navigate}
            title={t(id ? 'EDIT_POI' : 'ADD_POI')}
            onBack={() => navigate('/pois')}
            breadcrumbs={[
              { title: t('POIS'), href: '/pois' },
              { title: t(id ? 'EDIT' : 'ADD') }
            ]}
            actions={headerActions({
              submitting,
              pristine,
              backLink: '/pois',
              onDelete: id ? onDelete : undefined
            })}
          />
          <BaseTabs
            baseTab="information"
            tabs={defineTabs(errors, submitFailed)}
          />
        </BasicForm>
      )}
    </Form>
  );
};

export default ManagePoisPage;
