import { useTranslation } from 'react-i18next';
import {
  Block,
  Center,
  DoughnutChart,
  DoughnutDataset,
  HStack,
  ScrollArea,
  Typography,
  VStack,
  XelaColor
} from '@codepoint-pt/xela';
import { Dashboard } from './DashboardPage';
import { useContext } from 'react';
import { InfoContext } from '../../Context';

const colors = [
  XelaColor.Gray10,
  XelaColor.Blue4,
  XelaColor.Red4,
  XelaColor.Yellow4,
  XelaColor.Pink4,
  XelaColor.Indigo4,
  XelaColor.Orange4,
  XelaColor.Purple4,
  XelaColor.Green4,
  XelaColor.Blue6,
  XelaColor.Red6,
  XelaColor.Yellow6,
  XelaColor.Pink6,
  XelaColor.Indigo6,
  XelaColor.Orange6,
  XelaColor.Purple6,
  XelaColor.Green6,
  XelaColor.Blue2,
  XelaColor.Red2,
  XelaColor.Yellow2,
  XelaColor.Pink2,
  XelaColor.Indigo2,
  XelaColor.Orange2,
  XelaColor.Purple2,
  XelaColor.Green2,
  XelaColor.Blue10,
  XelaColor.Red10,
  XelaColor.Yellow10,
  XelaColor.Pink10,
  XelaColor.Indigo10,
  XelaColor.Orange10,
  XelaColor.Purple10,
  XelaColor.Green10
];

interface Props {
  data?: Dashboard;
}

const ByCountry = ({ data }: Props) => {
  const { info } = useContext(InfoContext);
  const { t, i18n } = useTranslation();

  const numbers = Object.keys(data?.byCountry || []).map(
    (key) => data?.byCountry[key]
  );

  const labels = Object.keys(data?.byCountry || []).map((key) => {
    if (key === 'undefined') return t('UNDEFINED');
    const country = (info?.countries || []).find((c) => c._id == key);
    if (country) return country.name[i18n.language] || t('UNDEFINED');
    return t('UNDEFINED');
  });

  return (
    <Block padding="30px" bg="white" borderRadius="16px">
      <Typography variant="subheadline">{t('USERS_BY_COUNTRY')}</Typography>
      <HStack spacing="32px">
        <Center style={{ position: 'relative' }}>
          <DoughnutChart
            width="200px"
            cutout={45}
            datasets={[
              new DoughnutDataset(
                'Gender',
                numbers,
                colors,
                undefined,
                undefined,
                16
              )
            ]}
            labels={labels}
          />
        </Center>
        <VStack>
          <ScrollArea.Autosize maxHeight={200} scrollbarSize={8} type="auto">
            <VStack spacing="8px" style={{ paddingRight: '60px' }}>
              {labels.map((l, index) => (
                <HStack spacing="12px" key={index}>
                  <Block
                    bg={colors[index % colors.length]}
                    padding="15px"
                    borderRadius="4px"
                  />
                  <Typography variant="caption">
                    {l}: <b>{numbers[index]}</b>
                  </Typography>
                </HStack>
              ))}
            </VStack>
          </ScrollArea.Autosize>
        </VStack>
      </HStack>
    </Block>
  );
};

export default ByCountry;
