import { useTranslation } from 'react-i18next';
import {
  Block,
  Center,
  Grid,
  HStack,
  Icon,
  Space,
  Typography,
  VStack,
  XelaColor
} from '@codepoint-pt/xela';
import { useEffect, useState } from 'react';
import { useFetch } from 'use-http';
import { AnyObject } from '../../models/Generic';
import ByGender from './ByGender';
import ByCountry from './ByCountry';
import ActiveAlerts from './ActiveAlerts';

export interface Dashboard {
  total: number;
  programs: number;
  pois: number;
  byGender: AnyObject;
  byCountry: AnyObject;
}

const DashboardPage = () => {
  const [data, setData] = useState<Dashboard>();
  const { t } = useTranslation();
  const { get } = useFetch('/info//statistics');

  useEffect(() => {
    const init = async () => {
      const { data, success } = await get();
      if (success) setData(data);
    };
    init();
  }, []);

  return (
    <Block>
      <Space h={20} />
      <Grid gutter="lg">
        <Grid.Col xs={12}>
          <Typography variant="title3">{t('DASHBOARD')}</Typography>
        </Grid.Col>
        <Grid.Col xs={4}>
          <Block padding="20px 30px" bg="white" borderRadius="16px">
            <HStack spacing="16px">
              <Center
                width="52px"
                height="52px"
                borderRadius="99px"
                bg={XelaColor.Green12}
              >
                <Icon
                  icon="essentials_userCircle"
                  size={28}
                  color={XelaColor.Green1}
                />
              </Center>
              <VStack width="auto">
                <Typography variant="title3">{data?.total}</Typography>
                <Typography variant="caption" color={XelaColor.Gray8}>
                  {t('TOTAL_USERS')}
                </Typography>
              </VStack>
            </HStack>
          </Block>
        </Grid.Col>
        <Grid.Col xs={4}>
          <Block padding="20px 30px" bg="white" borderRadius="16px">
            <HStack spacing="16px">
              <Center
                width="52px"
                height="52px"
                borderRadius="99px"
                bg={XelaColor.Blue12}
              >
                <Icon
                  icon="authoring_floatLeft"
                  size={24}
                  color={XelaColor.Blue1}
                />
              </Center>
              <VStack width="auto">
                <Typography variant="title3">{data?.programs}</Typography>
                <Typography variant="caption" color={XelaColor.Gray8}>
                  {t('TOTAL_PROGRAMS')}
                </Typography>
              </VStack>
            </HStack>
          </Block>
        </Grid.Col>
        <Grid.Col xs={4}>
          <Block padding="20px 30px" bg="white" borderRadius="16px">
            <HStack spacing="16px">
              <Center
                width="52px"
                height="52px"
                borderRadius="99px"
                bg={XelaColor.Purple12}
              >
                <Icon
                  icon="essentials_location"
                  size={30}
                  color={XelaColor.Purple1}
                />
              </Center>
              <VStack width="auto">
                <Typography variant="title3">{data?.pois}</Typography>
                <Typography variant="caption" color={XelaColor.Gray8}>
                  {t('TOTAL_POIS')}
                </Typography>
              </VStack>
            </HStack>
          </Block>
        </Grid.Col>
        <Grid.Col xs={6}>
          <ByGender data={data} />
        </Grid.Col>
        <Grid.Col xs={6}>
          <ByCountry data={data} />
        </Grid.Col>
        <Grid.Col xs={12}>
          <ActiveAlerts />
        </Grid.Col>
      </Grid>
    </Block>
  );
};

export default DashboardPage;
