import { LoadingOverlay } from '@codepoint-pt/xela';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import React, { useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFetch, { CachePolicies, IncomingOptions, Provider } from 'use-http';
import { showError } from './hooks/show-notification/show-notification';
import { AnyObject } from './models/Generic';
import { Info } from './models/Info';
import { Language } from './models/Language';
import { User } from './models/User';
import { ErrorList } from './styles/BasicStyles';
import { isArrayOfStrings } from './utils/arrays/ArrayFunctions';
import { AvailableLanguages } from './utils/languages/AvailableTranslations';
import { AuthTokenKey, LanguageKey } from './utils/requests/LocalStorageKeys';

const { REACT_APP_API_URL } = process.env;

const InfoBasic = {
  defaultLanguage: '',
  translations: {},
  languages: [],
  categories: [],
  countries: [],
  municipalities: [],
  logTypes: [],
  maintenanceStatus: [],
  iosStore: '',
  androidStore: ''
};

export interface UserContextProps {
  user?: User | undefined;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}

export interface InfoContextProps {
  info: Info;
  setInfo: React.Dispatch<React.SetStateAction<Info>>;
}

export const InfoContext = React.createContext<InfoContextProps>({
  info: InfoBasic,
  setInfo: () => null
});
export const UserContext = React.createContext<UserContextProps>({
  user: undefined,
  setUser: () => null
});
export const LanguageContext = React.createContext<Language[]>([]);

const InitTranslations = (
  translations: AnyObject,
  fallbackLng: string,
  supportedLngs: Array<string>
) => {
  const resources: AnyObject = {};
  Object.keys(translations).forEach((tag: string) => {
    resources[tag] = { translation: translations[tag] };
  });
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng,
      interpolation: { escapeValue: false },
      resources,
      supportedLngs
    });
};

const Context: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [info, setInfo] = useState<Info>(InfoBasic);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const httpOptions: IncomingOptions = {
    cachePolicy: CachePolicies.CACHE_AND_NETWORK,
    interceptors: {
      request: ({ options }) => {
        const authToken = localStorage.getItem(AuthTokenKey);
        const lang = localStorage.getItem(LanguageKey);
        options.headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
          'Accept-Language': lang || ''
        };
        if (options.body instanceof FormData) {
          delete options.headers['Content-Type'];
        }
        return options;
      },
      response: async ({ response }) => {
        if (response.status >= 400) {
          let message: string | React.ReactElement =
            'There is an issue trying to reach the api';

          if (response.status === 404) {
            message = "The api request doesn't exist";
          }

          if (response?.data) {
            message = response?.data?.message || message;
            const stack = response?.data?.data;
            if (isArrayOfStrings(stack)) {
              message = (
                <>
                  {message}
                  <ErrorList>
                    {stack.map((e: string, i: number) => (
                      <li key={i}>{e}</li>
                    ))}
                  </ErrorList>
                </>
              );
            }
          }
          showError({
            title: response?.data?.title || 'Invalid server request',
            message: message
          });

          if (response?.status == 401) {
            localStorage.clear();
            setUser(undefined);
            navigate('/login');
          }
        }
        return response;
      }
    }
  };
  const { get } = useFetch(REACT_APP_API_URL, httpOptions);

  useEffect(() => {
    const init = async () => {
      try {
        const { data, success } = await get('/info');
        if (success) {
          setInfo(data);
          InitTranslations(
            data.translations,
            data.defaultLanguage,
            data.languages
          );
          setLanguages(AvailableLanguages(data.languages));
          if (data.user) setUser(data.user);
          if (data.token) localStorage.setItem(AuthTokenKey, data.token);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        navigate('/server-error');
      }
    };
    init();
  }, []);

  if (loading) return <LoadingOverlay visible={true} size="large" />;

  return (
    <Provider url={REACT_APP_API_URL} options={httpOptions}>
      <InfoContext.Provider value={{ info, setInfo }}>
        <UserContext.Provider value={{ user, setUser }}>
          <LanguageContext.Provider value={languages}>
            {children}
          </LanguageContext.Provider>
        </UserContext.Provider>
      </InfoContext.Provider>
    </Provider>
  );
};

export default React.memo(Context);
