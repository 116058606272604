import {
  PasswordInput,
  BaseButton,
  Grid,
  PasswordVerificationInput,
  Typography,
  HStack
} from '@codepoint-pt/xela';
import yup from '@codepoint-pt/yup-validations';
import { Field, withTypes } from 'react-final-form';
import { useValidationSchema } from '../../../hooks/use-validation/use-validation-schema';
import { BasicForm } from '../../../styles/BasicStyles';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../AuthLayout';
import useFetch from 'use-http';
import { showError } from '../../../hooks/show-notification/show-notification';
import { useParams, useNavigate } from 'react-router';
import { useState, useContext } from 'react';
import AppleStore from '../../../assets/icons/apple_store.svg';
import AndroidStore from '../../../assets/icons/google_store.svg';
import styled from 'styled-components';
import { InfoContext } from '../../../Context';
import { isPassword } from '@codepoint-pt/yup-validations';
import { useSearchParams } from 'react-router-dom';

const StoreButton = styled.img`
  width: 40%;
  cursor: pointer;
`;

interface Values {
  password: string;
  confirm: string;
}

const { Form } = withTypes<Values>();

const ValidateTenantAccount = () => {
  const [finish, setFinish] = useState(false);
  const { info } = useContext(InfoContext);
  const { put } = useFetch('/auth');
  const { t } = useTranslation();
  const { id, token } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isAdmin = searchParams.get('platform') === 'bo';

  const onSubmit = async (values: Values) => {
    if (values.password !== values.confirm) {
      return showError({
        title: t('ERROR'),
        message: t('PASSWORDS_DONT_MATCH')
      });
    }

    const { success, data } = await put('/validate-account', {
      ...values,
      token,
      _id: id
    });

    if (success) {
      setFinish(true);
    } else {
      if (data.expired) {
        navigate('/request-validation');
      }
    }
  };

  const openStore = (link?: string) => {
    if (link) window.open(link);
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(
        yup.object({
          password: isPassword.required(),
          confirm: isPassword.required()
        })
      )}
    >
      {({ handleSubmit, submitting }) => (
        <AuthLayout
          message={t('VALIDATE_ACCOUNT')}
          subMessage={t('VALIDATE_ACCOUNT_MESSAGE')}
        >
          {finish ? (
            <>
              <Typography variant="body-bold">{t('SUCCESS')}</Typography>
              <Typography gutterBottom={false} variant="body">
                {t(
                  isAdmin
                    ? 'ACCOUNT_ADMIN_VALIDATION_SUCCESS'
                    : 'ACCOUNT_VALIDATION_SUCCESS'
                )}
              </Typography>
              {!isAdmin && (
                <Typography variant="body">
                  {t('APPLICATION_DOWNLOAD_LINKS')}
                </Typography>
              )}
              {isAdmin ? (
                <HStack justifyContent="center">
                  <BaseButton size="md" onClick={() => navigate('/login')}>
                    {t('LOGIN')}
                  </BaseButton>
                </HStack>
              ) : (
                <HStack justifyContent="space-between">
                  <StoreButton
                    src={AppleStore}
                    onClick={() => openStore(info?.iosStore)}
                  />
                  <StoreButton
                    src={AndroidStore}
                    onClick={() => openStore(info?.androidStore)}
                  />
                </HStack>
              )}
            </>
          ) : (
            <BasicForm onSubmit={handleSubmit}>
              <Grid gutter={24}>
                <Grid.Col xs={12}>
                  <Field name="password">
                    {(props) => (
                      <PasswordVerificationInput
                        {...props}
                        label={t('PASSWORD')}
                        placeholder={t('CHOOSE_YOUR_PASSWORD')}
                      />
                    )}
                  </Field>
                </Grid.Col>
                <Grid.Col xs={12}>
                  <Field name="confirm">
                    {(props) => (
                      <PasswordInput
                        {...props}
                        label={t('CONFIRM_PASSWORD')}
                        placeholder={t('REPEAT_YOUR_PASSWORD')}
                      />
                    )}
                  </Field>
                </Grid.Col>
                <Grid.Col xs={12}>
                  <BaseButton
                    size="md"
                    fullWidth
                    type="submit"
                    loading={submitting}
                  >
                    {t('VALIDATE')}
                  </BaseButton>
                </Grid.Col>
              </Grid>
            </BasicForm>
          )}
        </AuthLayout>
      )}
    </Form>
  );
};

export default ValidateTenantAccount;
