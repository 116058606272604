import { DefaultListPage, Grid, SearchInput } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import { AnyObject } from '../../models/Generic';
import { Trail } from '../../models/Trail';

const RoutesListPage = DefaultListPage<Trail>();

const TrailsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    {
      title: t('NAME'),
      dataIndex: 'name',
      sortable: true,
      render: (value: AnyObject) => <ActiveTranslation value={value} />
    }
  ];

  const { get } = useFetch('/trails');

  return (
    <RoutesListPage
      translate={t}
      request={{
        get,
        searchFields: ['name|intl', 'description|intl']
      }}
      storage="TRAILS_FILTERS"
      header={{
        navigate,
        title: t('ROUTES'),
        actions: [],
        breadcrumbs: [{ title: t('ROUTES'), href: '/trails' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/trails/${row._id}`)
      }}
    />
  );
};

export default TrailsPage;
