import { LoadingOverlay, PageHeader, toFormData } from '@codepoint-pt/xela';
import yup from '@codepoint-pt/yup-validations';
import { useEffect, useState } from 'react';
import { withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import FormPrompt from '../../components/prompt/FormPrompt';
import BaseTabs from '../../components/tabs/BaseTabs';
import useIntlValidation from '../../hooks/use-validation/use-inlt-validation';
import useSectionValidation from '../../hooks/use-validation/use-section-validation';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { Trail } from '../../models/Trail';
import { BasicForm } from '../../styles/BasicStyles';
import InformationTab from './tabs/InformationTab';
import MultimediaTab from './tabs/MultimediaTab';
import MapTab from './tabs/MapTab';
import { isUrl } from '@codepoint-pt/yup-validations';
import AlertsTab from './tabs/AlertsTab';

const { Form } = withTypes<Trail>();

const ManageTrailPage = () => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const intlObject = useIntlValidation();
  const { get, post, put, del } = useFetch('/trails');

  useEffect(() => {
    const init = async () => {
      if (id) {
        const { data, success } = await get(`/${id}`);
        if (success) setInitialValues(data);
        else navigate('/trails');
      }
      setLoading(false);
    };
    init();
  }, [id]);

  const onSubmit = async (values: Trail) => {
    let success = false;
    delete values.alerts;
    const payload = toFormData(values);

    if (id) {
      const result = await put(`/${values._id}`, payload);
      success = result.success;
    } else {
      const result = await post(payload);
      success = result.success;
    }

    if (success) navigate('/trails');
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={useValidationSchema(
        yup.object({
          name: intlObject({ required: true }),
          description: intlObject({ required: true }),
          color: yup.string().required(),
          video: isUrl
        })
      )}
    >
      {({ handleSubmit, submitting, pristine, errors, submitFailed }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <FormPrompt
            when={!pristine && !submitting && !loading}
            message={t('PROMPT_MESSAGE')}
          />
          <PageHeader
            navigate={navigate}
            title={t(id ? 'EDIT_ROUTE' : 'ADD_ROUTE')}
            onBack={() => navigate('/trails')}
            breadcrumbs={[
              { title: t('ROUTES'), href: '/trails' },
              { title: t(id ? 'EDIT' : 'ADD') }
            ]}
            actions={headerActions({
              submitting,
              pristine,
              backLink: '/trails',
              deleteTitle: 'DELETE_ROUTE',
              deleteMessage: 'DELETE_ROUTE_MESSAGE'
            })}
          />
          <BaseTabs
            baseTab="information"
            tabs={[
              {
                value: 'information',
                label: t('INFORMATION'),
                children: (
                  <InformationTab
                    subValidate={(fields) =>
                      useSectionValidation(errors, fields, submitFailed)
                    }
                  />
                ),
                error: useSectionValidation(
                  errors,
                  ['name', 'description'],
                  submitFailed
                )
              },
              {
                value: 'map',
                label: t('MAP'),
                children: <MapTab />
              },
              {
                value: 'multimedia',
                label: t('MULTIMEDIA'),
                children: <MultimediaTab />
              },
              {
                value: 'alerts',
                label: t('ALERTS'),
                children: <AlertsTab />
              }
            ]}
          />
        </BasicForm>
      )}
    </Form>
  );
};

export default ManageTrailPage;
