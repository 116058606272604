import {
  BaseButton,
  DefaultListPage,
  Grid,
  Icon,
  RefreshFunction,
  SearchInput
} from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import { Category } from '../../models/Category';
import { AnyObject } from '../../models/Generic';
import { Municipality } from '../../models/Municipality';
import { Poi } from '../../models/Poi';

const PoisListPage = DefaultListPage<Poi>();

const PoisPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { get } = useFetch('/pois');

  const columns = [
    {
      title: t('NAME'),
      dataIndex: 'name',
      sortable: true,
      render: (value: AnyObject) => <ActiveTranslation value={value || 'N/A'} />
    },
    {
      title: t('CATEGORY'),
      dataIndex: 'category',
      sortable: true,
      render: (value: Category) => (
        <ActiveTranslation value={value?.name || 'N/A'} />
      )
    },
    {
      title: t('MUNICIPALITY'),
      dataIndex: 'municipality',
      sortable: true,
      render: (value: Municipality) => (
        <ActiveTranslation value={value?.name || 'N/A'} />
      )
    }
  ];

  return (
    <PoisListPage
      translate={t}
      request={{
        get,
        searchFields: ['name|intl', 'description|intl']
      }}
      storage="POIS_FILTERS"
      header={{
        navigate,
        title: t('POIS'),
        actions: [
          {
            name: t('ADD_NEW'),
            icon: <Icon icon="basics_add" color="white" />,
            onClick: () => navigate('/pois/new')
          }
        ],
        breadcrumbs: [{ title: t('POIS'), href: '/pois' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/pois/${row._id}`)
      }}
    />
  );
};

export default PoisPage;
