import {
  BaseButton,
  DefaultListPage,
  Grid,
  RefreshFunction,
  SearchInput
} from '@codepoint-pt/xela';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import { User } from '../../models/User';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import { AnyObject } from '../../models/Generic';

const UserListPage = DefaultListPage<User>();

const UsersPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { get, del } = useFetch('/users');

  const columns = [
    { title: t('NAME'), dataIndex: 'name', sortable: true },
    {
      title: t('EMAIL'),
      dataIndex: 'email',
      sortable: true
    },
    {
      title: t('BIRTHDATE'),
      dataIndex: 'birth',
      render: (value: string) =>
        value ? dayjs(value).format('DD/MM/YYYY') : t('NO_RECORD')
    },
    {
      title: t('GENDER'),
      dataIndex: 'gender',
      sortable: true,
      render: (value: string) => (value ? t(value) : t('NO_RECORD'))
    },
    {
      title: t('NATIONALITY'),
      dataIndex: 'country',
      sortable: true,
      render: (value: AnyObject) =>
        value ? <ActiveTranslation value={value?.name} /> : t('NO_RECORD')
    },
    {
      title: t('CITY'),
      dataIndex: 'city',
      sortable: true,
      render: (value: string) => value || t('NO_RECORD')
    },
    {
      title: t('REGISTERDATE'),
      dataIndex: 'createdAt',
      sortable: true,
      render: (value: string) =>
        value ? dayjs(value).format('HH:mm DD/MM/YYYY') : t('NO_RECORD')
    }
  ];

  return (
    <UserListPage
      translate={t}
      request={{
        get,
        searchFields: ['name', 'email']
      }}
      storage="USER_FILTERS"
      header={{
        navigate,
        title: t('USERS'),
        breadcrumbs: [{ title: t('USERS'), href: '/users' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/users/${row._id}`)
      }}
    />
  );
};

export default UsersPage;
