import {
  Typography,
  XelaColor,
  Link,
  TextInput,
  PasswordInput,
  Icon,
  BaseButton,
  Grid
} from '@codepoint-pt/xela';
import yup from '@codepoint-pt/yup-validations';
import { useContext } from 'react';
import { Field, withTypes } from 'react-final-form';
import { LoginValues } from '../../../models/Info';
import { UserContext } from '../../../Context';
import { useValidationSchema } from '../../../hooks/use-validation/use-validation-schema';
import { BasicForm } from '../../../styles/BasicStyles';
import { AuthTokenKey } from '../../../utils/requests/LocalStorageKeys';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../AuthLayout';
import useFetch from 'use-http';

const { Form } = withTypes<LoginValues>();

export const LoginPage = () => {
  const { post } = useFetch('/auth');
  const { setUser } = useContext(UserContext);
  const { t } = useTranslation();

  const onSubmit = async (values: LoginValues) => {
    const { data, success } = await post('/login', values);
    if (success) {
      localStorage.setItem(AuthTokenKey, data.token);
      setUser(data.user);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(
        yup.object({
          email: yup.string().email().required(),
          password: yup.string().required().min(6)
        })
      )}
    >
      {({ handleSubmit, submitting }) => (
        <AuthLayout message={t('LOGIN_ACCOUNT')}>
          <BasicForm onSubmit={handleSubmit}>
            <Grid gutter={24}>
              <Grid.Col xs={12}>
                <Field name="email">
                  {(props) => (
                    <TextInput
                      {...props}
                      label={t('EMAIL')}
                      icon={<Icon icon="essentials_envelope" size={14} />}
                      type="email"
                    />
                  )}
                </Field>
              </Grid.Col>
              <Grid.Col xs={12}>
                <Field name="password">
                  {(props) => (
                    <PasswordInput
                      {...props}
                      icon={<Icon icon="basics_lock" size={14} />}
                      label={t('PASSWORD')}
                    />
                  )}
                </Field>
              </Grid.Col>
              <Grid.Col xs={12}>
                <Typography variant="button-medium" color={XelaColor.Blue6}>
                  <Link href="/recover-password">{t('RECOVER_PASSWORD')}</Link>
                </Typography>
              </Grid.Col>
              <Grid.Col xs={12}>
                <BaseButton
                  size="md"
                  fullWidth
                  type="submit"
                  loading={submitting}
                >
                  {t('LOGIN')}
                </BaseButton>
              </Grid.Col>
            </Grid>
          </BasicForm>
        </AuthLayout>
      )}
    </Form>
  );
};

export default LoginPage;
