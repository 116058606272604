import { Block, Icon, Tabs, XelaColor } from '@codepoint-pt/xela';

interface TabProps {
  value: string;
  label: string;
  error?: boolean;
  children: React.ReactNode;
}

interface BaseTabsProps {
  tabs: TabProps[];
  baseTab?: string;
  keepMounted?: boolean;
}

const BaseTabs: React.FC<BaseTabsProps> = ({
  tabs,
  baseTab = 'info',
  keepMounted = true
}) => (
  <Tabs keepMounted={keepMounted} variant="pills" defaultValue={baseTab}>
    <Block bg="white" borderRadius="16px" padding="10px">
      <Tabs.List>
        {tabs.map((tab) => (
          <Tabs.Tab
            key={tab.value}
            value={tab.value}
            rightSection={
              tab.error && (
                <Icon icon="basics_exclamationCircle" color={XelaColor.Red5} />
              )
            }
          >
            {tab.label}
          </Tabs.Tab>
        ))}
      </Tabs.List>
    </Block>

    {tabs.map((tab) => (
      <Tabs.Panel key={tab.value} value={tab.value}>
        {tab.children}
      </Tabs.Panel>
    ))}
  </Tabs>
);

export default BaseTabs;
