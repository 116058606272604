import { Block, ColorInput, Grid, TextInput } from '@codepoint-pt/xela';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import TranslatableFields from '../../../components/inputs/TranslatableFields';

interface InformationProps {
  subValidate: (fields: string[]) => boolean;
}

const InformationTab = ({ subValidate }: InformationProps) => {
  const { t } = useTranslation();
  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Grid>
        <Grid.Col xs={12}>
          <TranslatableFields
            subValidate={subValidate}
            fields={[
              {
                label: 'NAME',
                name: 'name',
                type: 'text'
              },
              {
                label: 'DESCRIPTION',
                name: 'description',
                type: 'textarea'
              }
            ]}
          />
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="color">
            {(props) => (
              <ColorInput
                {...props}
                label={t('COLOR')}
                placeholder={t('CHOOSE_COLOR')}
                format="rgba"
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="video">
            {(props) => (
              <TextInput
                {...props}
                label={t('VIDEO')}
                placeholder={t('LINK_TO_VIDEO')}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
    </Block>
  );
};

export default InformationTab;
