import { PasswordInput, BaseButton, Grid } from '@codepoint-pt/xela';
import yup from '@codepoint-pt/yup-validations';
import { Field, withTypes } from 'react-final-form';
import { ResetValues } from '../../../models/Info';
import { useValidationSchema } from '../../../hooks/use-validation/use-validation-schema';
import { BasicForm } from '../../../styles/BasicStyles';
import { useTranslation } from 'react-i18next';
import {
  showError,
  showSuccess
} from '../../../hooks/show-notification/show-notification';
import { useParams, useNavigate } from 'react-router';
import AuthLayout from '../AuthLayout';
import useFetch from 'use-http';

const { Form } = withTypes<ResetValues>();

export const RecoverPage = () => {
  const { post } = useFetch('/auth');
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();

  const onSubmit = async (values: ResetValues) => {
    if (values.password !== values.confirm_password) {
      return showError({
        title: t('ERROR'),
        message: t('PASSWORDS_DONT_MATCH')
      });
    }

    const { success } = await post('/reset-password', { ...values, token });

    if (success) {
      showSuccess({
        title: t('BO_SUCCESS'),
        message: t('PASSWORD_CHANGED')
      });

      navigate('/login');
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(
        yup.object({
          password: yup.string().required().min(6),
          confirm_password: yup.string().required().min(6)
        })
      )}
    >
      {({ handleSubmit, submitting }) => (
        <AuthLayout message={t('RESET_PASSWORD')}>
          <BasicForm onSubmit={handleSubmit}>
            <Grid gutter={24}>
              <Grid.Col xs={12}>
                <Field name="password">
                  {(props) => (
                    <PasswordInput {...props} label={t('PASSWORD')} />
                  )}
                </Field>
              </Grid.Col>
              <Grid.Col xs={12}>
                <Field name="confirm_password">
                  {(props) => (
                    <PasswordInput {...props} label={t('CONFIRM_PASSWORD')} />
                  )}
                </Field>
              </Grid.Col>
              <Grid.Col xs={12}>
                <BaseButton
                  size="md"
                  fullWidth
                  type="submit"
                  loading={submitting}
                >
                  {t('SAVE')}
                </BaseButton>
              </Grid.Col>
            </Grid>
          </BasicForm>
        </AuthLayout>
      )}
    </Form>
  );
};

export default RecoverPage;
