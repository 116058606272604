import LoginPage from '../../pages/auth/components/LoginPage';
import ValidateAccount from '../../pages/auth/components/ValidateAccount';
import RequestValidation from '../../pages/auth/components/RequestValidation';
import RecoverPasswordPage from '../../pages/auth/components/RecoverPasswordPage';
import ResetPasswordPage from '../../pages/auth/components/ResetPasswordPage';
import DashboardPage from '../../pages/dashboard/DashboardPage';
import ProfilePage from '../../pages/profile/ManageProfilePage';
import SearchPage from '../../pages/search/SearchPage';

const BaseRoutes = [
  {
    path: '/',
    component: <DashboardPage />,
    isAuthenticated: true
  },
  {
    path: '/search/:search',
    component: <SearchPage />,
    isAuthenticated: true
  },
  {
    path: '/my-profile',
    component: <ProfilePage />,
    isAuthenticated: true
  },
  {
    path: '/login',
    component: <LoginPage />,
    isAuthenticated: false,
    forceValidation: true
  },
  {
    path: '/validate-account/:id/:token',
    component: <ValidateAccount />,
    isAuthenticated: false,
    forceValidation: false
  },
  {
    path: '/request-validation',
    component: <RequestValidation />,
    isAuthenticated: false,
    forceValidation: false
  },
  {
    path: '/recover-password',
    component: <RecoverPasswordPage />,
    isAuthenticated: false,
    forceValidation: false
  },
  {
    path: '/reset-password/:token',
    component: <ResetPasswordPage />,
    isAuthenticated: false,
    forceValidation: false
  }
];

export default BaseRoutes;
