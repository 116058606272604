import {
  AnyObject,
  DefaultListPage,
  Grid,
  Icon,
  SearchInput
} from '@codepoint-pt/xela';
import useFetch from 'use-http';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import { Alert } from '../../models/Alert';
import dayjs from 'dayjs';

const TypedDefaultList = DefaultListPage<Alert>();

const AlertsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    {
      title: t('TITLE'),
      dataIndex: 'title',
      sortable: true,
      render: (value: AnyObject) => <ActiveTranslation value={value} />
    },
    {
      title: t('FROM_DATE'),
      dataIndex: 'start',
      sortable: true,
      render: (value: string) =>
        value ? dayjs(value).format('DD/MM/YYYY') : ''
    },
    {
      title: t('TO_DATE'),
      dataIndex: 'end',
      sortable: true,
      render: (value: string) =>
        value ? dayjs(value).format('DD/MM/YYYY') : ''
    },
    {
      title: t('TRAILS'),
      dataIndex: 'trails',
      render: (value: string[]) => value.length || 0
    },
    {
      title: t('POIS'),
      dataIndex: 'pois',
      render: (value: string[]) => value.length || 0
    }
  ];

  const { get } = useFetch('/alerts');

  return (
    <TypedDefaultList
      translate={t}
      request={{
        get,
        searchFields: ['title|intl', 'description|intl']
      }}
      storage="ALERTS_FILTERS"
      header={{
        navigate,
        title: t('ALERTS'),
        actions: [
          {
            name: t('ADD_NEW'),
            icon: <Icon icon="basics_add" color="white" />,
            onClick: () => navigate('/alerts/new')
          }
        ],
        breadcrumbs: [{ title: t('ALERTS'), href: '/alerts' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/alerts/${row._id}`)
      }}
    />
  );
};

export default AlertsPage;
