import { FC } from 'react';
import { useState } from 'react';
import {
  Grid,
  Block,
  Typography,
  XelaColor,
  BaseButton,
  Icon,
  GenericInput
} from '@codepoint-pt/xela';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import { useTranslation } from 'react-i18next';
import { EmailParams } from '../../models/EmailTemplate';

export interface HtmlInputProps {
  input: GenericInput<string>;
  placeholder?: string;
  params?: EmailParams[];
}

const HtmlInput: FC<HtmlInputProps> = ({ input, placeholder, params }) => {
  const [expandEditor, setExpandEditor] = useState(false);
  const { t } = useTranslation();
  return (
    <Block margin="20px 0">
      <Grid>
        <Grid.Col xs={12}>
          <Typography variant="body-bold">
            <span style={{ color: XelaColor.Red1 }}>{t('NOTE')}: </span>
            {t('EMAIL_TEMPLATE_TAG_WARNING')}
          </Typography>

          {params && params.length && (
            <Block margin="30px 0 10px 0">
              <Typography variant="body-bold" style={{ color: XelaColor.Red1 }}>
                {t('AVAILABLE_TAGS')}:
              </Typography>
              {params.map((param) => (
                <div key={param.name}>
                  <Typography variant="body-bold">
                    {`{{params.${param.name}}}`}:
                  </Typography>
                  <Typography variant="body"> {param.label}</Typography>
                </div>
              ))}
            </Block>
          )}
        </Grid.Col>
        <Grid.Col xs={12}>
          <Block style={{ textAlign: 'right' }}>
            <BaseButton
              action="warning"
              color="primary"
              leftIcon={
                <Icon
                  color="white"
                  icon={expandEditor ? 'arrows_minimise' : 'arrows_expand'}
                />
              }
              onClick={() => setExpandEditor(!expandEditor)}
              variant="filled"
            >
              {expandEditor ? 'Diminuir Editor' : 'Expandir Editor'}
            </BaseButton>
          </Block>
        </Grid.Col>
        <Grid.Col md={expandEditor ? 12 : 6} xs={12}>
          <AceEditor
            placeholder={placeholder}
            mode="html"
            theme="monokai"
            name="html"
            onLoad={(editorInstance) => {
              editorInstance.container.style.resize = 'both';
              document.addEventListener('mouseup', () =>
                editorInstance.resize()
              );
            }}
            onChange={input.onChange}
            fontSize={14}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            value={input.value}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2,
              useWorker: false
            }}
            style={{ width: '100%', height: '600px' }}
          />
        </Grid.Col>
        <Grid.Col md={expandEditor ? 12 : 6} xs={12}>
          <Block
            dangerouslySetInnerHTML={{
              __html: input.value || ''
            }}
            bg="#F5F5F5"
            padding="10px"
            borderRadius="6px"
          />
        </Grid.Col>
      </Grid>
    </Block>
  );
};

export default HtmlInput;
