import { HStack, XelaColor, Collapse, Icon } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { GroupOption, MenuOptionName } from '../MenuStyles';
import { useEffect, useState } from 'react';
import { MenuOptionProps } from '../MenuOptions';
import { useLocation } from 'react-router';

interface GroupProps {
  isOpen: boolean;
  option: MenuOptionProps;
  renderMenuOptions: React.FC<MenuOptionProps>;
}

const Group: React.FC<GroupProps> = ({ isOpen, option, renderMenuOptions }) => {
  const [groupIsExpanded, setGroupIsExpanded] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (!isOpen) setGroupIsExpanded(false);
  }, [isOpen]);

  const isActive = () => {
    let active = false;
    (option.options || []).forEach((opt) => {
      if (opt.url && location.pathname.indexOf(opt.url) > -1) {
        active = true;
      }
    });
    return active;
  };

  return (
    <GroupOption isActive={isActive()}>
      <HStack
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setGroupIsExpanded(!groupIsExpanded)}
      >
        <HStack alignItems="center">
          {option.icon}
          {isOpen && (
            <MenuOptionName
              color={XelaColor.Gray3}
              variant="body-small-bold"
              isMenu={true}
            >
              {t(option.name || '')}
            </MenuOptionName>
          )}
        </HStack>
        {isOpen && (
          <Icon icon={groupIsExpanded ? 'basics_up' : 'basics_down'} />
        )}
      </HStack>
      {option.options && option.options.length > 0 && (
        <Collapse in={isOpen && groupIsExpanded}>
          <div style={{ paddingTop: '8px' }}>
            {option.options.map((option: MenuOptionProps) =>
              renderMenuOptions(option)
            )}
          </div>
        </Collapse>
      )}
    </GroupOption>
  );
};

export default Group;
