import { transitionsDelay } from "../../styles/BasicStyles";
import { breakpoints } from "../../styles/Breakpoints";
import styled from "styled-components";

export const LayoutWrapper = styled.div<{ isOpen?: boolean }>`
  display: flex;
  width: 100%;
  margin: 10px;
  margin-left: ${({ isOpen }) => (isOpen ? "310px" : "116px")};
  transition: margin-left ${transitionsDelay} linear;

  & > div:first-child {
    width: 100%;
    min-height: calc(100vh - 20px);
  }

  @media ${breakpoints.md} {
    margin-left: 10px;
    margin-top: 70px;

    & > div:first-child {
      min-height: calc(100vh - 80px);
    }
  }
`;
