import {
  Grid,
  SelectInput,
  SearchInput,
  FilterChildrenProps
} from '@codepoint-pt/xela';
import Platforms from '../../utils/platforms/Platforms';
import { useTranslation } from 'react-i18next';

const TranslationFilters: React.FC<FilterChildrenProps> = ({
  filters,
  handleChange
}) => {
  const { t } = useTranslation();
  return (
    <Grid gutter="xl">
      <Grid.Col span={4}>
        <SelectInput
          data={Platforms}
          clearable
          placeholder={t('SELECT_PLATFORM')}
          keyLabel="tag"
          translate={t}
          input={{
            value: filters.platform,
            onChange: (v: string | null) => handleChange('platform', v)
          }}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <SearchInput
          input={{
            value: filters.search,
            onChange: (v: string | null) => handleChange('search', v, 1000)
          }}
          placeholder={t('SEARCH_BY_TAG_TRANSLATION')}
          onSearch={(v: string | null) => handleChange('search', v)}
        />
      </Grid.Col>
    </Grid>
  );
};

export default TranslationFilters;
