import {
  GenericInput,
  Grid,
  Typography,
  Icon,
  HStack
} from '@codepoint-pt/xela';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AnyObject } from '../../models/Generic';
import ActiveTranslation from '../languages/ActiveTranslation';
import IntlSelectInput from './IntlSelectInput';
import { PrimaryColor } from '../../styles/Colors';

const PoiPill = styled.div<{ $color: string }>`
  display: inline-block;
  margin-top: 15px;
  margin-right: 15px;
  padding: 10px 15px;
  background: ${({ $color }) => $color || PrimaryColor};
  color: white;
  border-radius: 16px;
  align-items: center;
`;

const RemoveIcon = styled.div`
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
`;

interface ManageTrailsProps extends Omit<FieldRenderProps<string[]>, 'input'> {
  input: GenericInput<string[]>;
  trails: AnyObject[];
  afterChange?: (selected: string[]) => void;
}

const ManageTrailList = ({ input, trails, afterChange }: ManageTrailsProps) => {
  const { t } = useTranslation();
  const value = input.value || [];

  const addPoint = (v: string | null) => {
    if (v && v !== '') {
      const result = [...(input.value || [])];
      result.push(v);
      input.onChange(result);
      if (afterChange) afterChange(result);
    }
  };

  const handleRemove = (index: number) => {
    const result = [...(input.value || [])];
    result.splice(index, 1);
    input.onChange(result);
    if (afterChange) afterChange(result);
  };

  return (
    <Grid gutter="xs">
      <Grid.Col xs={12}>
        <Typography>{t('ASSOCIATE_TRAILS')}</Typography>
      </Grid.Col>
      <Grid.Col xs={12}>
        <IntlSelectInput
          searchable
          data={trails.filter((p) => !value.includes(p._id))}
          input={{
            value: undefined,
            onChange: addPoint
          }}
        />
      </Grid.Col>
      <Grid.Col xs={12}>
        {value.map((id, index) => {
          const trail = trails.find((p) => p._id === id);
          return (
            <PoiPill key={id} $color={trail?.color}>
              <HStack>
                <ActiveTranslation value={trail?.name} />
                <RemoveIcon onClick={() => handleRemove(index)}>
                  <Icon icon="basics_xCircle" color="white" size={16} />
                </RemoveIcon>
              </HStack>
            </PoiPill>
          );
        })}
      </Grid.Col>
    </Grid>
  );
};

export default ManageTrailList;
