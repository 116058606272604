import { Block, Grid, LoadingOverlay } from '@codepoint-pt/xela';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { Field, useFormState } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { useFetch } from 'use-http';
import ManagePoiList from '../../../components/inputs/ManagePoiList';
import UploadGeojson from '../../../components/inputs/UploadGeojson';
import Geojson from '../../../components/maps/Geojson';
import { GetCategoryIcon } from '../../../models/Category';
import { AnyObject } from '../../../models/Generic';
import { GeoJson } from '../../../models/Maps';

const latitudeDefault = 38.01737;
const longitudeDefault = -7.86536;

const MapTab = () => {
  const [loading, setLoading] = useState(true);
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  const [geojson, setGeojson] = useState<GeoJson | undefined>(undefined);
  const [pois, setPois] = useState<AnyObject[]>([]);
  const [marks, setMarks] = useState<AnyObject[]>([]);

  const { id } = useParams();
  const { get } = useFetch('/trails');
  const { get: getPois } = useFetch('/pois/dropdown');
  const formState = useFormState();

  const refocusMap = (geojson: GeoJson) => {
    if (map) {
      const bounds = new google.maps.LatLngBounds();
      geojson.features.forEach(function (feature) {
        feature.geometry.coordinates.forEach(function (coord) {
          bounds.extend(new google.maps.LatLng(coord[1], coord[0]));
        });
      });
      map.fitBounds(bounds);
    }
    setGeojson(geojson);
  };

  const onUpdatePois = (selected: string[]) => {
    const result: AnyObject[] = [];
    selected.forEach((id) => {
      const point = pois.find((p) => p._id === id);
      if (point)
        result.push({
          ...point,
          position: {
            lat: parseFloat(point.coordinates?.latitude),
            lng: parseFloat(point.coordinates?.longitude)
          }
        });
    });
    setMarks(result);
  };

  useEffect(() => {
    const init = async () => {
      const { data, success } = await get(`${id}/geojson`);
      if (success) refocusMap(data);
      const { data: poisData, success: poiSuccess } = await getPois();
      if (poiSuccess) setPois(poisData);
      setLoading(false);
    };
    init();
  }, []);

  useEffect(() => {
    onUpdatePois(formState.values['pois'] || []);
  }, [formState.values['pois'], loading]);

  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Grid>
        <Grid.Col xs={12}>
          <Field name="path">
            {(props) => <UploadGeojson {...props} refocusMap={refocusMap} />}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12}>
          <LoadingOverlay visible={loading} />
          <GoogleMap
            id="geojson"
            mapContainerStyle={{ width: '100%', height: '500px' }}
            zoom={7}
            onLoad={setMap}
            options={{ minZoom: 3 }}
            center={{
              lat: latitudeDefault,
              lng: longitudeDefault
            }}
          >
            {geojson && (
              <Field name="color">
                {({ input }) => (
                  <Geojson
                    geojson={geojson}
                    strokeColor={input?.value || 'black'}
                  />
                )}
              </Field>
            )}
            {marks.map((mark) => (
              <Marker
                key={mark._id}
                position={mark.position}
                icon={GetCategoryIcon(mark?.category)}
              />
            ))}
          </GoogleMap>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="pois">
            {(props) => <ManagePoiList {...props} pois={pois} />}
          </Field>
        </Grid.Col>
      </Grid>
    </Block>
  );
};

export default MapTab;
