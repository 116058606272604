import { BaseButton, Block, Grid } from '@codepoint-pt/xela';
import { useState, useCallback, useEffect } from 'react';
import LanguageTabs from '../../../../components/languages/LanguageTabs';
import EmptyState from '../../../../components/empty/EmptyState';
import { DayBlock } from '../../../../models/Program';
import DraggableBlock from './DraggableBlock';
import { useDrop } from 'react-dnd';
import { ObjectID } from 'bson';
import { GenericInput } from '../../../../models/Generic';
import BlockModal from './BlockModal';

interface ModalState {
  open: boolean;
  initialValues?: DayBlock;
  index?: number;
}
interface Params {
  input: GenericInput<DayBlock[]>;
  t: (v: string) => string;
}

const BlockInput = ({ input, t }: Params) => {
  const [modal, setModal] = useState<ModalState>({ open: false });
  const [blocks, setBlocks] = useState<DayBlock[]>([]);

  useEffect(() => {
    if (input && input.value) {
      setBlocks(input.value);
    }
  }, [input]);
  const [, drop] = useDrop(() => ({
    accept: 'block',
    drop: () => ({ name: 'blocks' })
  }));

  const findCard = useCallback(
    (id: string) => {
      const found = blocks.findIndex((c) => c._id === id);
      return {
        block: blocks[found],
        index: found
      };
    },
    [blocks]
  );

  const moveBlock = (id: string, atIndex: number) => {
    const { block, index } = findCard(id);
    const result = [...blocks];
    result.splice(index, 1);
    result.splice(atIndex, 0, block);
    setBlocks(result);
    input.onChange(result);
  };

  const handleClose = (block?: DayBlock) => {
    if (block) {
      setBlocks((old) => {
        const result = [...old];
        if ((modal.index || modal.index === 0) && modal.index > -1) {
          result.splice(modal?.index, 1, block);
        } else {
          result.push({ ...block, _id: new ObjectID().toString() });
        }
        input.onChange(result);
        return result;
      });
    }
    setModal({ open: false });
  };

  const handleDelete = (index: number) => {
    setBlocks((old) => {
      const result = [...old];
      result.splice(index, 1);
      input.onChange(result);
      return result;
    });
  };

  return (
    <Grid>
      <Grid.Col xs={12} style={{ textAlign: 'right' }}>
        <BaseButton action="secondary" onClick={() => setModal({ open: true })}>
          {t('ADD_BLOCK_DAYS')}
        </BaseButton>
      </Grid.Col>
      <Grid.Col xs={12}>
        <div ref={drop} style={{ paddingBottom: '150px' }}>
          <LanguageTabs>
            {(lang) => (
              <Block padding="20px 0">
                {!blocks ||
                  (blocks.length === 0 && (
                    <EmptyState
                      icon="files_note"
                      title="NO_BLOCK_CONTENT"
                      tag="NO_BLOCK_CONTENT_MESSAGE"
                    />
                  ))}
                {blocks.map((block, index) => (
                  <DraggableBlock
                    key={index}
                    block={block}
                    t={t}
                    lang={lang}
                    handleDelete={() => handleDelete(index)}
                    handleEdit={() =>
                      setModal({ open: true, initialValues: block, index })
                    }
                    moveBlock={moveBlock}
                    findCard={findCard}
                  />
                ))}
              </Block>
            )}
          </LanguageTabs>
        </div>
      </Grid.Col>

      <BlockModal
        opened={modal.open}
        onClose={handleClose}
        initialValues={modal.initialValues}
        t={t}
      />
    </Grid>
  );
};
export default BlockInput;
