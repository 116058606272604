import { LoadScript } from '@react-google-maps/api';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

const REACT_APP_GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <LoadScript
      id="script-loader"
      libraries={['places']}
      googleMapsApiKey={REACT_APP_GOOGLE_API_KEY || ''}
    >
      <App />
    </LoadScript>
  </BrowserRouter>
);

reportWebVitals();
