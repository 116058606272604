import { Block } from '@codepoint-pt/xela';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import BlockInput from '../components/day/BlockInput';

const DaysTab = () => {
  const { t } = useTranslation();

  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Field name="days">{(props) => <BlockInput t={t} {...props} />}</Field>
    </Block>
  );
};

export default DaysTab;
