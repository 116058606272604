import { Grid, Modal, useMantineTheme } from '@mantine/core';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { Translation } from '../../models/Translation';
import { BasicForm } from '../../styles/BasicStyles';
import Platforms from '../../utils/platforms/Platforms';
import useIntlValidation from '../../hooks/use-validation/use-inlt-validation';
import { BaseButton, SelectInput, TextInput, Icon } from '@codepoint-pt/xela';
import IntlTextAreaInput from '../../components/inputs/IntlTextAreaInput';
import useFetch from 'use-http';
import yup, { isTag } from '@codepoint-pt/yup-validations';

interface Params {
  opened: boolean;
  onClose: (changed: boolean) => void;
  initialValues?: Translation;
}

const { Form } = withTypes<Translation>();

const TranslationModal: React.FC<Params> = ({
  opened,
  onClose,
  initialValues
}) => {
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const intlObject = useIntlValidation();
  const { post, put } = useFetch('/translations');

  const onSubmit = async (values: Translation) => {
    if (values._id) {
      const { success } = await put(`/${values.tag}`, values);
      if (success) onClose(true);
    } else {
      const { success } = await post(values);
      if (success) onClose(true);
    }
  };

  return (
    <Modal
      overlayColor={theme.colors.gray[2]}
      size="md"
      padding="xl"
      centered
      opened={opened}
      onClose={() => onClose(false)}
      title={
        initialValues && initialValues._id
          ? t('EDIT_TRANSLATION')
          : t('CREATE_TRANSLATION')
      }
      closeOnClickOutside={false}
    >
      <Form
        onSubmit={onSubmit}
        validate={useValidationSchema(
          yup.object({
            tag: isTag.required(),
            value: intlObject({ required: true }),
            platform: yup
              .string()
              .required()
              .oneOf(Platforms.map((p) => p._id))
          })
        )}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitting }) => (
          <BasicForm onSubmit={handleSubmit}>
            <Grid gutter="xl">
              <Grid.Col span={12}>
                <Field name="tag">
                  {(props) => (
                    <TextInput
                      {...props}
                      label={t('TAG')}
                      placeholder={t('TRANSLATION_TAG_PLACEHOLDER')}
                      disabled={!!initialValues?._id}
                    />
                  )}
                </Field>
              </Grid.Col>
              <Grid.Col span={12}>
                <Field name="platform">
                  {(props) => (
                    <SelectInput
                      {...props}
                      label={t('PLATFORM')}
                      placeholder={t('PLATFORM')}
                      keyLabel="tag"
                      translate={t}
                      data={Platforms}
                    />
                  )}
                </Field>
              </Grid.Col>
              <Grid.Col span={12}>
                <Field name="value">
                  {(props) => (
                    <IntlTextAreaInput
                      {...props}
                      label={t('TRANSLATION_VALUE')}
                      placeholder="TRANSLATION_VALUE_PLACEHOLDER"
                    />
                  )}
                </Field>
              </Grid.Col>
              <Grid.Col span={12} style={{ textAlign: 'right' }}>
                <BaseButton
                  loading={submitting}
                  type="submit"
                  leftIcon={<Icon color="white" icon="essentials_save" />}
                >
                  {t('SAVE')}
                </BaseButton>
              </Grid.Col>
            </Grid>
          </BasicForm>
        )}
      </Form>
    </Modal>
  );
};

export default TranslationModal;
