import { Icon } from '@codepoint-pt/xela';

export type MenuType = 'link' | 'divider' | 'dropdown';

export interface MenuOptionProps {
  type: MenuType;
  name?: string;
  url?: string;
  icon?: React.ReactElement;
  options?: MenuOptionProps[];
  superuser?: boolean;
}

export const MenuOptions: MenuOptionProps[] = [
  {
    type: 'link',
    name: 'DASHBOARD',
    url: '/',
    icon: <Icon icon="charts_pieChart" size={16} />
  },
  {
    type: 'link',
    name: 'ADMINISTRATORS',
    url: '/admins',
    icon: <Icon icon="essentials_key" size={16} />
  },
  {
    type: 'link',
    name: 'USERS',
    url: '/users',
    icon: <Icon icon="essentials_user" size={16} />
  },
  {
    type: 'link',
    name: 'ALERTS',
    url: '/alerts',
    icon: <Icon icon="tasks_calendarNoAccess" size={16} />
  },
  {
    type: 'link',
    name: 'ROUTES',
    url: '/trails',
    icon: <Icon icon="authoring_curvedConnector" size={16} />
  },
  {
    type: 'link',
    name: 'PROGRAMS',
    url: '/programs',
    icon: <Icon icon="authoring_floatLeft" size={16} />
  },
  {
    type: 'link',
    name: 'POIS',
    url: '/pois',
    icon: <Icon icon="essentials_location" size={16} />
  },
  {
    type: 'link',
    name: 'TRANSLATIONS',
    url: '/translations',
    icon: <Icon icon="essentials_globe" size={16} />,
    superuser: true
  },
  {
    type: 'link',
    name: 'EMAIL_TEMPLATES',
    url: '/email-templates',
    icon: <Icon icon="essentials_envelope" size={16} />,
    superuser: true
  },
  {
    type: 'link',
    name: 'DYNAMIC_PAGES',
    url: '/dynamic-pages',
    icon: <Icon icon="files_textDocument" size={16} />,
    superuser: true
  },
  {
    type: 'link',
    name: 'LOGS',
    url: '/logs',
    icon: <Icon icon="technology_microSdCard" size={16} />,
    superuser: true
  }
];
