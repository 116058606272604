import { Block, Grid } from '@codepoint-pt/xela';
import { Field } from 'react-final-form';
import AlertsInput from '../../../components/inputs/AlertsInput';
import { useParams } from 'react-router-dom';

const AlertsTab = () => {
  const { id } = useParams();
  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Grid>
        <Grid.Col xs={12}>
          <Field name="alerts">
            {(props) => <AlertsInput {...props} on="pois" id={id || ''} />}
          </Field>
        </Grid.Col>
      </Grid>
    </Block>
  );
};

export default AlertsTab;
