import {
  LoadingOverlay,
  PageHeader,
  toFormData,
  Block,
  Grid,
  ImageInput,
  AspectRatios,
  TextInput,
  Icon
} from '@codepoint-pt/xela';
import yup from '@codepoint-pt/yup-validations';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Field, withTypes } from 'react-final-form';
import { User } from '../../models/User';
import { BasicForm } from '../../styles/BasicStyles';
import { useContext, useState, useEffect } from 'react';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { UserContext } from '../../Context';
import { showSuccess } from '../../hooks/show-notification/show-notification';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import PasswordModal from './PasswordModal';

const { Form } = withTypes<User>();

const ManageProfilePage = () => {
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  const { get, put } = useFetch('/auth/profile');

  useEffect(() => {
    const init = async () => {
      if (user?._id) {
        const { data, success } = await get();
        if (success) setInitialValues(data);
      }
      setLoading(false);
    };
    init();
  }, [user?._id]);

  const onSubmit = async (values: User) => {
    const payload = toFormData(values);
    const result = await put(payload);
    if (result.success) {
      showSuccess({
        title: t('BO_SUCCESS'),
        message: t('PROFILE_UPDATED')
      });
      setUser(result.data);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={useValidationSchema(
        yup.object({
          name: yup.string().required(),
          email: yup.string().email().required()
        })
      )}
    >
      {({ handleSubmit, submitting, pristine }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <PageHeader
            navigate={navigate}
            title={t('EDIT_PROFILE')}
            breadcrumbs={[{ title: t('EDIT_PROFILE'), href: '/my-profile' }]}
            actions={headerActions({
              submitting,
              pristine,
              extra: [
                {
                  name: t('CHANGE_PASSWORD'),
                  action: 'secondary',
                  icon: <Icon icon="arrows_loop" color="white" size={12} />,
                  onClick: () => setModal(true)
                }
              ]
            })}
          />
          <Block
            borderRadius="16px"
            bg="white"
            padding="50px 15px"
            margin="10px 0"
          >
            <Grid style={{ marginTop: '30px' }}>
              <Grid.Col md={4} sm={3} xs={0}></Grid.Col>
              <Grid.Col md={4} sm={6} xs={12}>
                <Grid>
                  <Grid.Col xs={12}>
                    <Field name="photo">
                      {(props) => (
                        <ImageInput
                          {...props}
                          ratio={AspectRatios['1:1']}
                          label={t('PHOTO')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Field name="name">
                      {(props) => (
                        <TextInput {...props} label={`${t('NAME')}`} />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Field name="email">
                      {(props) => (
                        <TextInput
                          {...props}
                          label={`${t('EMAIL')}`}
                          htmlType="email"
                        />
                      )}
                    </Field>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </Block>
          <PasswordModal t={t} opened={modal} onClose={() => setModal(false)} />
        </BasicForm>
      )}
    </Form>
  );
};

export default ManageProfilePage;
