import { Grid, TextInput } from '@codepoint-pt/xela';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

const UserForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid>
        <Grid.Col md={4} sm={3} xs={0}></Grid.Col>
        <Grid.Col md={4} sm={6} xs={12}>
          <Field name="name">
            {(props) => <TextInput {...props} label={`${t('NAME')} *`} />}
          </Field>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col md={4} sm={3} xs={0}></Grid.Col>
        <Grid.Col md={4} sm={6} xs={12}>
          <Field name="email">
            {(props) => (
              <TextInput
                {...props}
                label={`${t('EMAIL')} *`}
                htmlType="email"
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default UserForm;
