import { BaseButton, Typography, VStack } from '@codepoint-pt/xela';
import styled from 'styled-components';
import Codepoint from '../../assets/icons/codepoint.svg';
import IllustrationIcon from '../../assets/icons/server.svg';

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0;
`;

const LogoImage = styled.img`
  cursor: pointer;
  position: absolute;
  top: 44px;
  left: 32px;
  width: 200px;
`;

const Illustration = styled.img`
  max-width: 50vw;
  max-height: 40vh;
`;

const ServerErrorPage = () => {
  return (
    <Container>
      <LogoImage
        src={Codepoint}
        onClick={() => window.open('https://codepoint.pt', '_blank')}
      />
      <VStack spacing="30px" justifyContent="center" alignItems="center">
        <Illustration src={IllustrationIcon} />
        <Typography variant="title3">Server Error</Typography>
        <Typography variant="body" align="center">
          The api seems to be down.
          <br />
          If this error persists please contact the platform administrator.
        </Typography>
        <BaseButton onClick={() => window.open('/', '_self')}>
          Refresh platform
        </BaseButton>
      </VStack>
    </Container>
  );
};

export default ServerErrorPage;
