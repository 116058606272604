import {
  Block,
  HStack,
  Icon,
  LoadingOverlay,
  PageHeader,
  SearchInput,
  Typography,
  VStack,
  XelaColor,
  ActionIcon,
  Avatar
} from '@codepoint-pt/xela';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';
import EmptyState from '../../components/empty/EmptyState';
import Placeholder from '../../assets/images/placeholder.png';
import ActiveTranslation from '../../components/languages/ActiveTranslation';

let timeout: NodeJS.Timer;

const SearchPage = () => {
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any[]>([]);
  const { search } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { get } = useFetch('/search');

  useEffect(() => {
    const searchBy = async () => {
      setInput(search || '');
      setLoading(true);
      const { data, success } = await get(search);
      if (success) setResults(data);
      setLoading(false);
    };
    searchBy();
  }, [search]);

  const handleSearch = (value: string, time: number) => {
    setInput(value);
    if (value !== '') {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        navigate(`/search/${value}`);
      }, time);
    }
  };

  const handleClick = (type: string, id: string) => {
    switch (type) {
      case 'ADMINISTRATORS':
        return navigate(`/admins/${id}`);
      case 'ARTICLES':
        return navigate(`/articles/${id}`);
      case 'BO_PARTNERS':
        return navigate(`/partners/${id}`);
      case 'PARTNER_TESTIMONIES':
        return navigate(`/partner-testimonies/${id}`);
      case 'BO_SOLUTIONS':
        return navigate(`/solutions/${id}`);
      case 'TEAM':
        return navigate(`/team/${id}`);
      case 'TEAM_TESTIMONIES':
        return navigate(`/team-testimonies/${id}`);
    }
  };

  return (
    <Block>
      <LoadingOverlay visible={loading} overlayOpacity={0} overlayBlur={1} />
      <PageHeader
        title={t('SEARCH')}
        breadcrumbs={[{ title: t('SEARCH'), href: '/search' }]}
        actions={[]}
      />
      <Block bg="white" borderRadius="16px" padding="15px" margin="30px 0">
        <SearchInput
          input={{
            value: input,
            onChange: (v: string | null) => handleSearch(v || '', 1000)
          }}
          onSearch={(v: string | null) => handleSearch(v || '', 0)}
        />
      </Block>
      {results.length === 0 && (
        <Block
          bg="white"
          borderRadius="16px"
          padding="50px 0"
          margin="30px 0 0 0"
        >
          <EmptyState icon="basics_search" />
        </Block>
      )}
      {results.map((result, index) => (
        <Block
          key={index}
          margin="0 0 30px 0"
          padding="15px"
          borderRadius="16px"
          bg="white"
        >
          <Typography variant="subheadline">{t(result.type)}</Typography>
          {result.results.map((entity: any, index: number) => (
            <Block key={index} margin="20px 0 0 0">
              <HStack justifyContent="space-between" spacing="10px">
                <HStack spacing="20px">
                  <Avatar
                    radius="xl"
                    size="xl"
                    src={entity?.icon?.url || Placeholder}
                  />
                  <VStack spacing="10px">
                    <Typography variant="body">
                      <ActiveTranslation value={entity?.title} />
                    </Typography>
                    {entity?.description && (
                      <Typography variant="body-small">
                        <ActiveTranslation value={entity?.description} />
                      </Typography>
                    )}
                  </VStack>
                </HStack>
                <ActionIcon
                  onClick={() => handleClick(result.type, entity._id)}
                >
                  <Icon
                    icon="basics_arrowRightCircle"
                    size={24}
                    color={XelaColor.Indigo8}
                  />
                </ActionIcon>
              </HStack>
            </Block>
          ))}
        </Block>
      ))}
    </Block>
  );
};

export default SearchPage;
