import {
  Block,
  DateRangeInput,
  Grid,
  LoadingOverlay,
  PageHeader
} from '@codepoint-pt/xela';
import yup from '@codepoint-pt/yup-validations';
import { useEffect, useState } from 'react';
import { Field, withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import TranslatableFields from '../../components/inputs/TranslatableFields';
import FormPrompt from '../../components/prompt/FormPrompt';
import useIntlValidation from '../../hooks/use-validation/use-inlt-validation';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { BasicForm } from '../../styles/BasicStyles';
import useSectionValidation from '../../hooks/use-validation/use-section-validation';
import { Alert } from '../../models/Alert';
import ManagePoiList from '../../components/inputs/ManagePoiList';
import { AnyObject } from '../../models/Generic';
import ManageTrailList from '../../components/inputs/ManageTrailList';

const { Form } = withTypes<Alert>();

const ManageAlertPage = () => {
  const [trails, setTrails] = useState<AnyObject[]>([]);
  const [pois, setPois] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const intlObject = useIntlValidation();

  const { get, post, put, del } = useFetch('/alerts');
  const { get: getPois } = useFetch('/pois/dropdown');
  const { get: getTrails } = useFetch('/trails/dropdown');

  useEffect(() => {
    const init = async () => {
      const { data: trailsData, success: trailsSuccess } = await getTrails();
      if (trailsSuccess) setTrails(trailsData);
      const { data: poisData, success: poiSuccess } = await getPois();
      if (poiSuccess) setPois(poisData);
      if (id) {
        const { data, success } = await get(`/${id}`);
        if (success)
          setInitialValues({
            ...data,
            dates: [new Date(data.start), new Date(data.end)]
          });
      }
      setLoading(false);
    };
    init();
  }, [id]);

  const onSubmit = async (values: Alert) => {
    let success = false;

    const payload = {
      ...values,
      start: values.dates[0],
      end: values.dates[1]
    };

    if (id) {
      const result = await put(`/${values._id}`, payload);
      success = result.success;
    } else {
      const result = await post(payload);
      success = result.success;
    }

    if (success) navigate('/alerts');
  };

  const onDelete = async () => {
    if (id) {
      setLoading(true);
      const { success } = await del(`/${id}`);
      if (success) navigate('/alerts');
      else setLoading(false);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={useValidationSchema(
        yup.object({
          title: intlObject({ required: true }),
          dates: yup.array().required().length(2)
        })
      )}
    >
      {({ handleSubmit, submitting, pristine, errors, submitFailed }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <FormPrompt
            when={!pristine && !submitting && !loading}
            message={t('PROMPT_MESSAGE')}
          />
          <PageHeader
            navigate={navigate}
            title={t(id ? 'EDIT_ALERT' : 'ADD_ALERT')}
            onBack={() => navigate('/alerts')}
            breadcrumbs={[
              { title: t('ALERTS'), href: '/alerts' },
              { title: t(id ? 'EDIT' : 'ADD') }
            ]}
            actions={headerActions({
              submitting,
              pristine,
              onDelete: id ? onDelete : undefined,
              backLink: '/alerts'
            })}
          />
          <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
            <Grid gutter="xl">
              <Grid.Col xs={12}>
                <TranslatableFields
                  subValidate={(fields) =>
                    useSectionValidation(errors, fields, submitFailed)
                  }
                  fields={[
                    {
                      label: 'TITLE',
                      name: 'title',
                      type: 'text'
                    },
                    {
                      label: 'DESCRIPTION',
                      name: 'description',
                      type: 'richtext'
                    }
                  ]}
                />
              </Grid.Col>
              <Grid.Col xs={12}>
                <Field name="dates">
                  {(props) => (
                    <DateRangeInput {...props} label={t('START_END_DATE')} />
                  )}
                </Field>
              </Grid.Col>
              <Grid.Col xs={12}>
                <Field name="trails">
                  {(props) => <ManageTrailList {...props} trails={trails} />}
                </Field>
              </Grid.Col>
              <Grid.Col xs={12}>
                <Field name="pois">
                  {(props) => <ManagePoiList {...props} pois={pois} />}
                </Field>
              </Grid.Col>
            </Grid>
          </Block>
        </BasicForm>
      )}
    </Form>
  );
};

export default ManageAlertPage;
