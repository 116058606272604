import { AspectRatios, Block, Grid } from '@codepoint-pt/xela';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import ImageListInput from '../../../components/inputs/ImageListInput';

const MultimediaTab = () => {
  const { t } = useTranslation();

  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Grid>
        <Grid.Col xs={12}>
          <Field name="images">
            {(props) => (
              <ImageListInput
                {...props}
                label={`${t('IMAGES')} *`}
                ratio={AspectRatios['3:2']}
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
    </Block>
  );
};

export default MultimediaTab;
