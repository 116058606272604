import DE from './german_flag.svg';
import EN from './english_flag.svg';
import FR from './france_flag.svg';
import IT from './italian_flag.svg';
import ES from './spanish_flag.svg';
import PT from './portuguese_flag.svg';

export const Languages = [
  { code: 'pt', name: 'Portuguese', tag: 'PORTUGUESE', icon: PT },
  { code: 'en', name: 'English', tag: 'ENGLISH', icon: EN },
  { code: 'es', name: 'Spanish', tag: 'SPANISH', icon: ES },
  { code: 'de', name: 'German', tag: 'GERMAN', icon: DE },
  { code: 'fr', name: 'French', tag: 'FRENCH', icon: FR },
  { code: 'it', name: 'Italian', tag: 'ITALIAN', icon: IT }
];

export const AvailableLanguages = (list: string[] = []) =>
  Languages.filter((l) => list.includes(l.code));
