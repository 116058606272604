import { EmailTemplate } from '../../models/EmailTemplate';
import { useTranslation } from 'react-i18next';
import {
  DefaultListPage,
  Grid,
  Icon,
  SearchInput,
  AnyObject
} from '@codepoint-pt/xela';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import ActiveTranslation from '../../components/languages/ActiveTranslation';

const baseTemplateID = '6347e52a032d0577adeb7c4a';
const EmailTemplatesListPage = DefaultListPage<EmailTemplate>();

const EmailTemplatesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    { title: t('NAME'), dataIndex: 'name', sortable: true },
    { title: t('TAG'), dataIndex: 'tag', sortable: true },
    {
      title: t('SUBJECT'),
      dataIndex: 'subject',
      sortable: true,
      render: (value: AnyObject) => <ActiveTranslation value={value} />
    }
  ];

  const { get } = useFetch('/email-templates');

  return (
    <EmailTemplatesListPage
      translate={t}
      request={{
        get,
        searchFields: ['name', 'subject']
      }}
      storage="EMAIL_TEMPLATES_FILTERS"
      header={{
        title: t('EMAIL_TEMPLATES'),
        actions: [
          {
            name: t('EDIT_HEADER_FOOTER'),
            icon: <Icon icon="basics_edit" color="white" />,
            onClick: () => navigate(`/email-templates/${baseTemplateID}`)
          }
        ],
        breadcrumbs: [{ title: t('EMAIL_TEMPLATES'), href: '/email-templates' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
              placeholder={t('SEARCH_NAME_SUBJECT')}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/email-templates/${row._id}`)
      }}
    />
  );
};

export default EmailTemplatesPage;
