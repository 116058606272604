import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import './styles/Fonts.css';
import Context from './Context';
import StyledProvider from './styles/StyledProvider';
import Routes from './routes/routes';

dayjs.extend(utc);
dayjs.extend(timezone);

const App = () => (
  <StyledProvider>
    <Context>
      <Routes />
    </Context>
  </StyledProvider>
);

export default App;
