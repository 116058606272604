import { BaseButton, Typography, VStack } from '@codepoint-pt/xela';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Codepoint from '../../assets/icons/codepoint.svg';
import IllustrationIcon from '../../assets/icons/not_found.svg';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0;
`;

const LogoImage = styled.img`
  cursor: pointer;
  position: absolute;
  top: 44px;
  left: 32px;
  width: 200px;
`;

const Illustration = styled.img`
  max-width: 80%;
`;

const NotFoundPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Container>
      <LogoImage
        src={Codepoint}
        onClick={() => window.open('https://codepoint.pt', '_blank')}
      />
      <VStack spacing="30px" justifyContent="center" alignItems="center">
        <Illustration src={IllustrationIcon} />
        <Typography variant="title3">{t('PAGE_NOT_FOUND')}</Typography>
        <Typography variant="body">
          {t('PAGE_NOT_FOUND_DESCRIPTION')}
        </Typography>
        <BaseButton onClick={() => navigate('/')}>
          {t('BACK_TO_HOMEPAGE')}
        </BaseButton>
      </VStack>
    </Container>
  );
};

export default NotFoundPage;
