import { RouteDefinition } from '../models/Generic';
import BaseRoutes from './data/BaseRoutes';
import BasicEntitiesRoutes from './data/BasicEntities';
import EntitiesRoutes from './data/Entities';

const CombinedRoutes: RouteDefinition[] = [
  ...BaseRoutes,
  ...BasicEntitiesRoutes,
  ...EntitiesRoutes
];
export default CombinedRoutes;
