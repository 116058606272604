import { useMemo, useContext } from 'react';
import { intlValidation } from '@codepoint-pt/yup-validations';
import { LanguageContext } from '../../Context';

export default function useIntlValidation() {
  const languages = useContext(LanguageContext);
  return useMemo(
    () => intlValidation(languages.map((l) => l.code)),
    [languages]
  );
}
