import { breakpoints } from "../../styles/Breakpoints";
import styled from "styled-components";

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 10;
  padding: 10px;
  background-color: white;
  display: none;
  align-items: center;
  border-radius: 32px;

  @media ${breakpoints.md} {
    display: flex;
  }
`;

export const Logo = styled.img`
  margin-left: 25px;
  max-height: 40px;
  max-width: 150px;
`;
