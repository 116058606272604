import { HStack, XelaColor, Block } from '@codepoint-pt/xela';
import { LayoutWrapper } from './LayoutStyles';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../Context';
import { MenuOptions } from '../menu/MenuOptions';
import Menu from '../menu/Menu';
import Logo from '../../assets/icons/logo.svg';
import Header from '../header/Header';

export const Layout: React.FC<{ children: any }> = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(true);
  const [hovered, setHovered] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    setUser(undefined);
  };

  const handleSearch = (e: string) => {
    if (e && e !== '') {
      navigate(`/search/${e}`);
    }
  };

  const showMenu = menuOpen || hovered;

  return (
    <Block bg={XelaColor.Gray11}>
      <HStack alignItems="flex-start" width="100%">
        <Header
          logo={{ image: Logo }}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
        />
        <Menu
          isOpen={showMenu}
          setMenuOpen={setMenuOpen}
          setHovered={setHovered}
          menuOptions={MenuOptions}
          user={user}
          handleLogout={handleLogout}
          handleSearch={handleSearch}
          handleProfile={() => navigate('/my-profile')}
          logo={{ image: Logo }}
        />
        <LayoutWrapper isOpen={showMenu}>
          <div>{children}</div>
        </LayoutWrapper>
      </HStack>
    </Block>
  );
};

export default Layout;
