/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Polyline, Marker } from '@react-google-maps/api';
import { Feature, GeoCoordinate, GeoJson, Overlay } from '../../models/Maps';

const flatten = (prev: any[], curr: any[]) => prev.concat(curr);

const makeOverlays = (features: Feature[]) => {
  const points = features
    .filter(
      (f: Feature) =>
        f.geometry &&
        (f.geometry.type === 'Point' || f.geometry.type === 'MultiPoint')
    )
    .map((feature: Feature) =>
      makeCoordinates(feature).map((coordinates: GeoCoordinate) =>
        makeOverlay(coordinates, feature)
      )
    )
    .reduce(flatten, [])
    .map((overlay: Overlay) => ({ ...overlay, type: 'point' }));

  const lines = features
    .filter(
      (f: Feature) =>
        f.geometry &&
        (f.geometry.type === 'LineString' ||
          f.geometry.type === 'MultiLineString')
    )
    .map((feature: Feature) =>
      makeCoordinates(feature).map((coordinates: GeoCoordinate) =>
        makeOverlay(coordinates, feature)
      )
    )
    .reduce(flatten, [])
    .map((overlay: Overlay) => ({ ...overlay, type: 'polyline' }));

  return points.concat(lines);
};

const makeOverlay = (coordinates: GeoCoordinate, feature: Feature) => {
  const overlay: Overlay = {
    feature
  };
  if (
    feature.geometry.type === 'Polygon' ||
    feature.geometry.type === 'MultiPolygon'
  ) {
    overlay.coordinates = coordinates[0];
    if (coordinates.length > 1) {
      overlay.holes = coordinates.slice(1);
    }
  } else {
    overlay.coordinates = coordinates;
  }
  return overlay;
};

const makePoint = (c: any) => ({ lat: c[1], lng: c[0] });

const makeLine = (l: any) => l.map(makePoint);

const makeCoordinates = (feature: Feature) => {
  const g = feature.geometry;
  if (g.type === 'Point') {
    return [makePoint(g.coordinates)];
  } else if (g.type === 'MultiPoint') {
    return g.coordinates.map(makePoint);
  } else if (g.type === 'LineString') {
    return [makeLine(g.coordinates)];
  } else if (g.type === 'MultiLineString') {
    return g.coordinates.map(makeLine);
  } else if (g.type === 'Polygon') {
    return g.coordinates.map(makeLine);
  } else if (g.type === 'MultiPolygon') {
    return g.coordinates.map((p: any) => p.map(makeLine));
  } else {
    return [];
  }
};

interface GeojsonProps {
  geojson: GeoJson;
  strokeColor?: string;
  strokeWidth?: number;
}

const Geojson = ({
  geojson,
  strokeColor = 'black',
  strokeWidth = 5
}: GeojsonProps) => {
  const overlays = makeOverlays(geojson?.features);
  return (
    <React.Fragment>
      {overlays.map((overlay: any, index: number) => {
        if (overlay.type === 'point') {
          return <Marker key={index} position={overlay.coordinates} />;
        }
        if (overlay.type === 'polyline') {
          return (
            <Polyline
              key={index}
              path={overlay.coordinates}
              options={{
                strokeColor: strokeColor,
                strokeWeight: strokeWidth
              }}
            />
          );
        }
        return null;
      })}
    </React.Fragment>
  );
};

export default Geojson;
