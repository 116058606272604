import { BaseTable, Block, Space, Typography } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';
import { Alert } from '../../models/Alert';
import { AnyObject } from '../../models/Generic';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AlertsTable = BaseTable<Alert>();

const ActiveAlerts = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20
  });
  const [rows, setRows] = useState<Alert[]>([]);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { get, loading } = useFetch('/alerts');

  useEffect(() => {
    const getData = async () => {
      const filters = { end: { $gte: dayjs().startOf('day').toString() } };
      const { data, success } = await get(
        `/${pagination.page}/${pagination.limit}?filters=${JSON.stringify(
          filters
        )}`
      );
      if (success) {
        setRows(data.items);
        setTotal(data.total);
      }
    };
    getData();
  }, [pagination]);

  const columns = [
    {
      title: t('TITLE'),
      dataIndex: 'title',
      render: (value: AnyObject) => <ActiveTranslation value={value} />
    },
    {
      title: t('FROM_DATE'),
      dataIndex: 'start',
      render: (value: string) =>
        value ? dayjs(value).format('DD/MM/YYYY') : ''
    },
    {
      title: t('TO_DATE'),
      dataIndex: 'end',
      render: (value: string) =>
        value ? dayjs(value).format('DD/MM/YYYY') : ''
    },
    {
      title: t('TRAILS'),
      dataIndex: 'trails',
      render: (value: string[]) => value.length || 0
    },
    {
      title: t('POIS'),
      dataIndex: 'pois',
      render: (value: string[]) => value.length || 0
    }
  ];

  const handleNewPage = (page: number) => {
    const pag = { ...pagination, page };
    setPagination(pag);
  };

  const handleNewLimit = (limit: number) => {
    const pag = { ...pagination, page: 1, limit };
    setPagination(pag);
  };

  return (
    <Block padding="30px" bg="white" borderRadius="16px">
      <Typography variant="subheadline">{t('ACTIVE_ALERTS')}</Typography>
      <Space h={30} />
      <AlertsTable
        columns={columns}
        rows={rows}
        onRowClick={(row) => navigate(`/alerts/${row._id}`)}
        loading={loading}
        translate={t}
        pagination={{
          total,
          page: pagination.page,
          setPage: handleNewPage,
          limit: pagination.limit,
          setLimit: handleNewLimit
        }}
      />
    </Block>
  );
};

export default ActiveAlerts;
