import { flattenObject } from '@codepoint-pt/xela';
import { ValidationErrors } from 'final-form';

export const useSectionValidation = (
  errors: ValidationErrors,
  list: string[],
  showError: boolean
) => {
  if (!showError || !errors) return false;
  let valid = true;
  const flatten = flattenObject(errors);
  list.forEach((item) => {
    if (errors[item] && errors[item] !== '') valid = false;
    if (flatten[`${item}.tag`] && flatten[`${item}.tag`] !== '') valid = false;
  });
  return !valid;
};

export default useSectionValidation;
