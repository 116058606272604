import { withTypes, Field } from 'react-final-form';
import { BasicForm } from '../../styles/BasicStyles';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import {
  Modal,
  XelaColor,
  Icon,
  Grid,
  BaseButton,
  PasswordInput,
  PasswordVerificationInput
} from '@codepoint-pt/xela';
import { useFetch } from 'use-http';
import {
  showError,
  showSuccess
} from '../../hooks/show-notification/show-notification';
import yup, { isPassword } from '@codepoint-pt/yup-validations';

interface PasswordForm {
  current: string;
  password: string;
  confirm_password: string;
}

interface Props {
  opened: boolean;
  onClose: () => void;
  t: (v: string) => string;
}

const { Form } = withTypes<PasswordForm>();

const PasswordModal: React.FC<Props> = ({ opened, onClose, t }) => {
  const { put, loading } = useFetch('/auth/change-password');

  const validation = useValidationSchema(
    yup.object({
      current: yup.string().required(),
      password: isPassword.required(),
      confirm_password: isPassword.required()
    })
  );

  const onSubmit = async (values: PasswordForm) => {
    if (values.password && values.password !== values.confirm_password) {
      return showError({
        title: t('ERROR'),
        message: t('PASSWORDS_DONT_MATCH')
      });
    }
    const { success } = await put(values);
    if (success) {
      showSuccess({
        title: t('BO_SUCCESS'),
        message: t('PASSWORD_CHANGE_SUCCESS')
      });
      onClose();
    }
  };

  return (
    <Modal
      overlayColor={XelaColor.Gray11}
      padding="xl"
      size="md"
      centered
      opened={opened}
      onClose={() => onClose()}
      title={t('CHANGE_PASSWORD')}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      {opened && (
        <Form onSubmit={onSubmit} validate={validation}>
          {({ handleSubmit }) => {
            return (
              <BasicForm onSubmit={handleSubmit}>
                <Grid>
                  <Grid.Col span={12}>
                    <Field name="current">
                      {(props) => (
                        <PasswordInput
                          {...props}
                          label={t('CURRENT_PASSWORD')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Field name="password">
                      {(props) => (
                        <PasswordVerificationInput
                          {...props}
                          label={t('NEW_PASSWORD')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Field name="confirm_password">
                      {(props) => (
                        <PasswordInput
                          {...props}
                          label={t('CONFIRM_NEW_PASSWORD')}
                        />
                      )}
                    </Field>
                  </Grid.Col>

                  <Grid.Col span={12} style={{ textAlign: 'right' }}>
                    <BaseButton
                      type="submit"
                      loading={loading}
                      leftIcon={<Icon color="white" icon="essentials_save" />}
                    >
                      {t('SAVE')}
                    </BaseButton>
                  </Grid.Col>
                </Grid>
              </BasicForm>
            );
          }}
        </Form>
      )}
    </Modal>
  );
};

export default PasswordModal;
