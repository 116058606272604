import { HStack, VStack, XelaColor, Typography } from '@codepoint-pt/xela';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { transitionsDelay } from '../../styles/BasicStyles';
import { PrimaryColor } from '../../styles/Colors';
import { breakpoints } from '../../styles/Breakpoints';
import styled, { css } from 'styled-components';

export const MenuWrapper = styled.div<{ isOpen: boolean }>`
  margin: 10px;
  padding: 32px 24px;
  position: fixed;
  top: 0;
  left: 0;
  transition: width ${transitionsDelay} linear, left ${transitionsDelay} linear;
  justify-content: space-between;
  border-radius: 32px;
  background: white;
  height: calc(100vh - 20px);
  width: ${({ isOpen }) => (isOpen ? '288px' : '96px')};
  display: flex;
  flex-direction: column;

  @media ${breakpoints.md} {
    margin: 0px;
    height: calc(100vh - 65px);
    width: 288px;
    top: 62px;
    left: ${({ isOpen }) => (isOpen ? '0px' : '-288px')};
    z-index: 10;
  }
`;

/* LOGO and CLOSE/OPEN MENU */

export const LogoWrapper = styled.div<{
  isOpen: boolean;
  maxWidth?: string;
  maxHeight?: string;
}>`
  overflow: hidden;
  transition: width ${transitionsDelay} linear,
    opacity ${transitionsDelay} linear;
  width: ${({ isOpen }) => (isOpen ? '100%' : '0%')};

  & img {
    max-width: ${({ maxWidth }) => maxWidth || '200px'};
    max-height: ${({ maxHeight }) => maxHeight || '60px'};
  }

  @media ${breakpoints.md} {
    display: none;
  }
`;

export const CollapseMenuWrapper = styled(HStack)`
  cursor: pointer;

  & svg path {
    transition: stroke ${transitionsDelay} linear;
  }

  &:hover {
    & svg path {
      stroke: ${PrimaryColor};
    }
  }

  @media ${breakpoints.md} {
    display: none;
  }
`;

/* SEARCH */

export const SearchWrapper = styled.div`
  margin-top: 24px;
  width: 100%;

  @media ${breakpoints.md} {
    margin-top: 0px;
  }
`;

export const SearchIconWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & svg path {
    transition: stroke ${transitionsDelay} linear;
  }

  &:hover {
    & svg path {
      stroke: ${PrimaryColor};
    }
  }
`;

/* MENU OPTIONS */

export const MenuOptionsWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  overflow-x: hidden;
`;

export const MenuOption = styled(NavLink)<NavLinkProps>`
  display: inline-block;
  width: 100%;
  padding: 8px;
  padding-left: 16px;
  cursor: pointer;
  width: 100%;
  text-decoration: none;

  span {
    line-height: 15px;
  }

  &.active {
    text-decoration: none;
    background-color: ${({ theme }) => theme.colors.lightPrimary};
    border-radius: 16px;
  }

  & > div:first-child {
    & span {
      transition: color ${transitionsDelay} linear;
    }

    & svg path {
      transition: stroke ${transitionsDelay} linear;
      stroke: ${XelaColor.Gray3};
    }

    &:hover {
      & span {
        color: ${PrimaryColor};
      }

      & svg path {
        stroke: ${PrimaryColor};
      }
    }
  }
`;

export const GroupOption = styled.div<{ isActive?: boolean }>`
  padding: 8px;
  padding-left: 16px;
  cursor: pointer;
  width: 100%;

  & > div:first-child {
    & span {
      transition: color ${transitionsDelay} linear;
      color: ${({ isActive }) => (isActive ? PrimaryColor : XelaColor.Gray3)};
    }

    & svg path {
      transition: stroke ${transitionsDelay} linear;
      stroke: ${({ isActive }) => (isActive ? PrimaryColor : XelaColor.Gray3)};
    }

    &:hover {
      & span {
        color: ${PrimaryColor};
      }

      & svg path {
        stroke: ${PrimaryColor};
      }
    }
  }
`;

export const MenuOptionIcon = styled.div`
  padding-right: 16px;
`;

export const MenuOptionName = styled(Typography)<{ isMenu: boolean }>`
  margin-left: 16px;
  white-space: nowrap;
  max-width: ${({ isMenu }) => (isMenu ? '165px' : '180px')};
  overflow: hidden;
`;

/* USER */

export const UserWrapper = styled(HStack)`
  cursor: pointer;

  & span {
    transition: color ${transitionsDelay} linear;
  }

  &:hover {
    & span {
      color: ${PrimaryColor};
    }
  }
`;

export const UserInfoWrapper = styled(VStack)<{ isOpen: boolean }>`
  transition: width ${transitionsDelay} ease-in,
    opacity ${transitionsDelay} ease-in;
  display: flex;
  width: ${({ isOpen }) => (isOpen ? '100%' : '0%')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  overflow: hidden;
`;

export const Logout = styled.div<{ isOpen: boolean }>`
  transition: opacity ${transitionsDelay} linear,
    display ${transitionsDelay} linear;
  cursor: pointer;

  & svg path {
    transition: stroke ${transitionsDelay} linear;
  }

  &:hover {
    & svg path {
      stroke: ${PrimaryColor};
    }
  }

  ${({ isOpen }) =>
    isOpen
      ? css`
          opacity: 1;
          display: block;
        `
      : css`
          display: none;
          opacity: 0;
        `}
`;

// export const MenuComponent = styled(({ children, isOpen }) => (
//   <VStack
//     width={isOpen ? "382px" : "48px"}
//     bg={XelaColor.White}
//     borderRadius="32px"
//     spacing="40px"
//     justifyContent="flex-start"
//     style={{ overflow: !isOpen ? "visible" : "hidden" }}
//   >
//     {children}
//   </VStack>
// ))`
//   transition: width 200ms linear;
//   margin: 24px;
//   padding: 32px 24px;
// `;

// export const LogoBox = styled<any>(Box)`
//   display: ${({ isOpen }) => (isOpen ? "block" : "none")};
//   width: ${({ isOpen }) => (isOpen ? "100%" : "0%")};
//   opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
//   transition: width opacity display 200ms linear;
// `;

// export const BrandLogo = styled.img`
//   max-width: 100%;
//   max-height: 100px;
// `;
