import {
  BaseButton,
  Block,
  DateRangeInput,
  DefaultListPage,
  Grid,
  Icon,
  SearchInput,
  SelectInput,
  TableSelectProps,
  Toast,
  XelaColor
} from '@codepoint-pt/xela';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import { InfoContext } from '../../Context';
import { Log } from '../../models/Log';

const LogsList = DefaultListPage<Log>();

const LogsPage = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [selected, onSelect] = useState<TableSelectProps<Log>[]>([]);
  const { info } = useContext(InfoContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { get, del, loading } = useFetch('/logs');
  const userRequests = useFetch('/users');

  useEffect(() => {
    const init = async () => {
      const { data, success } = await userRequests.get('/dropdown');
      if (success) setUsers(data);
    };
    init();
  }, []);

  const columns = [
    {
      title: t('USER'),
      dataIndex: 'curr_email',
      sortable: true
    },
    {
      title: t('ACTION'),
      dataIndex: 'action',
      sortable: true
    },
    {
      title: t('ENTITY'),
      dataIndex: 'entity',
      sortable: true
    },
    {
      title: t('VALUE'),
      dataIndex: 'entity_monitor',
      render: (value: string) => <ActiveTranslation value={value} />
    },
    {
      title: t('DATE'),
      dataIndex: 'createdAt',
      sortable: true,
      render: (value: string) =>
        value ? dayjs(value).format('DD/MM/YYYY HH:mm:ss') : t('NO_RECORD')
    }
  ];

  const handleDelete = async (refresh: () => void) => {
    const payload = selected.map((s) => s.key);
    const { success } = await del(payload);
    if (success) {
      onSelect([]);
      refresh();
    }
  };

  return (
    <Block>
      <LogsList
        translate={t}
        request={{
          get,
          searchFields: ['curr_name', 'curr_email', 'entity', 'entity_monitor']
        }}
        storage="LOGS_FILTERS"
        header={{
          navigate,
          title: t('LOGS'),
          actions: [],
          breadcrumbs: []
        }}
        filters={({ filters, handleChange }) => (
          <Grid gutter={24}>
            <Grid.Col xs={3}>
              <SearchInput
                input={{
                  value: filters.search,
                  onChange: (v: string | null) =>
                    handleChange('search', v, 1000)
                }}
                onSearch={(v) => handleChange('search', v)}
              />
            </Grid.Col>
            <Grid.Col xs={3}>
              <SelectInput
                clearable
                placeholder={t('ACTION')}
                input={{
                  value: filters.action,
                  onChange: (v: string | null) => handleChange('action', v, 1000)
                }}
                data={(info?.logTypes || []).map((s) => ({
                  _id: s,
                  name: s
                }))}
              />
            </Grid.Col>
            <Grid.Col xs={3}>
              <SelectInput
                clearable
                placeholder={t('USER')}
                input={{
                  value: filters.user,
                  onChange: (v: string | null) => handleChange('user', v, 1000)
                }}
                data={users}
                keyLabel="email"
              />
            </Grid.Col>
            <Grid.Col xs={3}>
              <DateRangeInput
                placeholder={t('DATE_RANGE')}
                clearable
                input={{
                  value: filters.dates,
                  onChange: (v: string | null) => handleChange('dates', v, 1000)
                }}
              />
            </Grid.Col>
          </Grid>
        )}
        table={{
          columns,
          selection: { selected, onSelect }
        }}
        tableExtras={({ refreshPage }) => (
          <>
            {selected.length && (
              <Toast
                title={`${selected.length} records selected`}
                position="bottom-center"
                isShown={selected.length > 0 ? 1 : 2}
                hide={() => null}
                firstAction={
                  <BaseButton
                    variant="outline"
                    action="error"
                    leftIcon={
                      <Icon
                        icon="essentials_bin"
                        color={XelaColor.Red5}
                        size={12}
                      />
                    }
                    onClick={() => handleDelete(refreshPage)}
                    loading={loading}
                  >
                    Delete
                  </BaseButton>
                }
              />
            )}
          </>
        )}
      />
    </Block>
  );
};

export default LogsPage;
