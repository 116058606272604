import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Translation } from '../../models/Translation';
import EmptyState from '../../components/empty/EmptyState';
import { LanguageContext } from '../../Context';
import TranslationModal from './TranslationModal';
import TranslationFilters from './TranslationFilters';
import { GetPlatformTag } from '../../utils/platforms/Platforms';
import {
  Block,
  DefaultListPage,
  Grid,
  Icon,
  LoadingOverlay,
  VStack,
  Tabs,
  XelaColor,
  Space,
  ActionIcon,
  Typography,
  Menu
} from '@codepoint-pt/xela';
import useFetch from 'use-http';
import { useNavigate } from 'react-router-dom';
import { showDelete } from '../../hooks/show-notification/show-notification';

const TranslateListPage = DefaultListPage<Translation>();

const TranslationsPage = () => {
  const [modal, setModal] = useState<{
    open: boolean;
    initialValues?: Translation;
  }>({ open: false });

  const { t } = useTranslation();
  const languages = useContext(LanguageContext);
  const navigate = useNavigate();
  const { get, del } = useFetch('/translations');

  return (
    <TranslateListPage
      translate={t}
      request={{
        get,
        searchFields: ['tag', 'value|intl']
      }}
      storage="TRANSLATION_FILTERS"
      header={{
        navigate,
        title: t('TRANSLATIONS'),
        actions: [
          {
            name: t('ADD_NEW'),
            icon: <Icon icon="basics_add" color="white" />,
            onClick: () => setModal({ open: true })
          }
        ],
        breadcrumbs: [{ title: t('TRANSLATIONS'), href: '/translations' }]
      }}
      filters={TranslationFilters}
    >
      {({ rows, loading, refreshPage }) => {
        const handleCloseModal = (changed: boolean) => {
          if (changed) refreshPage();
          setModal({ open: false });
        };

        const handleDelete = async (tag: string) => {
          showDelete({
            title: t('DELETE_TRANSLATION'),
            message: t('DELETE_TRANSLATION_MESSAGE'),
            onConfirm: async () => {
              await del(`/${tag}`);
              refreshPage();
            }
          });
        };

        return (
          <Block margin="0 0 30px 0">
            <Grid gutter="lg">
              <LoadingOverlay visible={loading} />
              {rows.length === 0 && (
                <Grid.Col span={12}>
                  <Block bg="white" borderRadius="16px" padding="10px 15px">
                    <EmptyState />
                  </Block>
                </Grid.Col>
              )}
              {rows.map((row) => (
                <Grid.Col key={row.tag} xs={12} sm={6} md={12} lg={6}>
                  <Block bg="white" borderRadius="16px" padding="10px 15px">
                    <Menu position="bottom-end">
                      <Menu.Target>
                        <ActionIcon
                          variant="light"
                          style={{
                            position: 'absolute',
                            top: '5px',
                            right: '5px'
                          }}
                        >
                          <Icon icon="interface_moreVertical" size={16} />
                        </ActionIcon>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item
                          icon={<Icon icon="essentials_edit" />}
                          onClick={() =>
                            setModal({ open: true, initialValues: row })
                          }
                        >
                          {t('EDIT')}
                        </Menu.Item>
                        <Menu.Item
                          color="red"
                          icon={<Icon icon="essentials_bin" color="red" />}
                          onClick={() => handleDelete(row.tag)}
                        >
                          {t('DELETE')}
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>

                    <VStack spacing="8px">
                      <Typography variant="body">
                        <Typography variant="body-bold">
                          {t('TAG')}:{' '}
                        </Typography>
                        {row.tag}
                      </Typography>
                      <Typography variant="body">
                        <Typography variant="body-bold">
                          {t('PLATFORM')}:{' '}
                        </Typography>
                        {t(GetPlatformTag(row.platform))}
                      </Typography>
                    </VStack>
                    <Space h={10} />
                    <Tabs radius="md" defaultValue="pt">
                      <Tabs.List>
                        {languages.map((lang) => (
                          <Tabs.Tab
                            key={lang.code}
                            icon={
                              <img style={{ width: '18px' }} src={lang.icon} />
                            }
                            value={lang.code}
                          >
                            {t(lang.tag)}
                          </Tabs.Tab>
                        ))}
                      </Tabs.List>

                      {languages.map((lang) => (
                        <Tabs.Panel key={lang.code} value={lang.code}>
                          <Block
                            borderRadius="16px"
                            margin="10px 0 0 0"
                            padding="10px"
                          >
                            <Typography variant="body">
                              {(row?.value && row.value[lang.code]) || 'N/A'}
                            </Typography>
                          </Block>
                        </Tabs.Panel>
                      ))}
                    </Tabs>
                  </Block>
                </Grid.Col>
              ))}
              <TranslationModal
                opened={modal.open}
                initialValues={modal.initialValues}
                onClose={handleCloseModal}
              />
            </Grid>
          </Block>
        );
      }}
    </TranslateListPage>
  );
};

export default TranslationsPage;
