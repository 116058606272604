import AdministratorsPage from '../../pages/administrators/AdministratorsPage';
import ManageAdministratorPage from '../../pages/administrators/ManageAdministratorPage';
import DynamicPagesPage from '../../pages/dynamicPages/DynamicPagesPage';
import ManageDynamicPagesPage from '../../pages/dynamicPages/ManageDynamicPagesPage';
import EmailTemplatesPage from '../../pages/emailTemplates/EmailTemplatesPage';
import ManageEmailTemplatePage from '../../pages/emailTemplates/ManageEmailTemplatePage';
import LogsPage from '../../pages/logs/LogsPage';
import TranslationsPage from '../../pages/translations/TranslationsPage';
import ManageUserPage from '../../pages/users/ManageUserPage';
import UsersPage from '../../pages/users/UsersPage';

const BasicEntitiesRoutes = [
  {
    path: '/admins',
    component: <AdministratorsPage />,
    isAuthenticated: true
  },
  {
    path: '/admins/new',
    component: <ManageAdministratorPage />,
    isAuthenticated: true
  },
  {
    path: '/admins/:id',
    component: <ManageAdministratorPage />,
    isAuthenticated: true
  },
  {
    path: '/users',
    component: <UsersPage />,
    isAuthenticated: true
  },
  {
    path: '/users/:id',
    component: <ManageUserPage />,
    isAuthenticated: true
  },
  {
    path: '/translations',
    component: <TranslationsPage />,
    isAuthenticated: true,
    superuser: true
  },
  {
    path: '/logs',
    component: <LogsPage />,
    isAuthenticated: true,
    superuser: true
  },
  {
    path: '/email-templates',
    component: <EmailTemplatesPage />,
    isAuthenticated: true,
    superuser: true
  },
  {
    path: '/email-templates/:id',
    component: <ManageEmailTemplatePage />,
    isAuthenticated: true,
    superuser: true
  },
  {
    path: '/dynamic-pages',
    component: <DynamicPagesPage />,
    isAuthenticated: true,
    superuser: true
  },
  {
    path: '/dynamic-pages/new',
    component: <ManageDynamicPagesPage />,
    isAuthenticated: true,
    superuser: true
  },
  {
    path: '/dynamic-pages/:id',
    component: <ManageDynamicPagesPage />,
    isAuthenticated: true,
    superuser: true
  }
];

export default BasicEntitiesRoutes;
