import AlertsPage from '../../pages/alerts/AlertsPage';
import ManageAlertPage from '../../pages/alerts/ManageAlertPage';
import ManagePoisPage from '../../pages/pois/ManagePoisPage';
import PoisPage from '../../pages/pois/PoisPage';
import ManageProgramsPage from '../../pages/programs/ManageProgramsPage';
import ProgramsPage from '../../pages/programs/ProgramsPage';
import ManageTrailPage from '../../pages/trails/ManageTrailPage';
import TrailsPage from '../../pages/trails/TrailsPage';

const EntityRoutes = [
  {
    path: '/pois',
    component: <PoisPage />,
    isAuthenticated: true
  },
  {
    path: '/pois/new',
    component: <ManagePoisPage />,
    isAuthenticated: true
  },
  {
    path: '/pois/:id',
    component: <ManagePoisPage />,
    isAuthenticated: true
  },
  {
    path: '/trails',
    component: <TrailsPage />,
    isAuthenticated: true
  },
  {
    path: '/trails/:id',
    component: <ManageTrailPage />,
    isAuthenticated: true
  },
  {
    path: '/programs',
    component: <ProgramsPage />,
    isAuthenticated: true
  },
  {
    path: '/programs/new',
    component: <ManageProgramsPage />,
    isAuthenticated: true
  },
  {
    path: '/programs/:id',
    component: <ManageProgramsPage />,
    isAuthenticated: true
  },
  {
    path: '/alerts',
    component: <AlertsPage />,
    isAuthenticated: true
  },
  {
    path: '/alerts/new',
    component: <ManageAlertPage />,
    isAuthenticated: true
  },
  {
    path: '/alerts/:id',
    component: <ManageAlertPage />,
    isAuthenticated: true
  }
];

export default EntityRoutes;
