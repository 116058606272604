import { Block, Grid, TextInput } from '@codepoint-pt/xela';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';
import ManagePoiList from '../../../components/inputs/ManagePoiList';
import { GetCategoryIcon } from '../../../models/Category';
import { AnyObject } from '../../../models/Generic';

const latitudeDefault = 38.01737;
const longitudeDefault = -7.86536;

const PoisTab = () => {
  const [loading, setLoading] = useState(true);
  const [pois, setPois] = useState<AnyObject[]>([]);
  const [marks, setMarks] = useState<AnyObject[]>([]);

  const { get: getPois } = useFetch('/pois/dropdown');
  const formState = useFormState();

  const onUpdatePois = (selected: string[]) => {
    const result: AnyObject[] = [];
    selected.forEach((id) => {
      const point = pois.find((p) => p._id === id);
      if (point)
        result.push({
          ...point,
          position: {
            lat: parseFloat(point.coordinates?.latitude),
            lng: parseFloat(point.coordinates?.longitude)
          }
        });
    });
    setMarks(result);
  };

  useEffect(() => {
    const init = async () => {
      const { data: poisData, success: poiSuccess } = await getPois();
      if (poiSuccess) setPois(poisData);
      setLoading(false);
    };
    init();
  }, []);

  useEffect(() => {
    onUpdatePois(formState.values['pois'] || []);
  }, [formState.values['pois'], loading]);

  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Grid>
        <Grid.Col xs={12}>
          <GoogleMap
            id="geojson"
            mapContainerStyle={{ width: '100%', height: '500px' }}
            zoom={7}
            options={{ minZoom: 3 }}
            center={{
              lat: latitudeDefault,
              lng: longitudeDefault
            }}
          >
            {marks.map((mark) => (
              <Marker
                key={mark._id}
                position={mark.position}
                icon={GetCategoryIcon(mark?.category)}
              />
            ))}
          </GoogleMap>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="pois">
            {(props) => <ManagePoiList {...props} pois={pois} />}
          </Field>
        </Grid.Col>
      </Grid>
    </Block>
  );
};

export default PoisTab;
