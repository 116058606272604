import { Block, Grid, SelectInput } from '@codepoint-pt/xela';
import { FC } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import TranslatableFields from '../../../components/inputs/TranslatableFields';
import ActiveTranslation from '../../../components/languages/ActiveTranslation';
import { Category } from '../../../models/Category';
import { Municipality } from '../../../models/Municipality';

interface InformationTabProps {
  categories: Category[];
  municipalities: Municipality[];
  subValidate: (fields: string[]) => boolean;
}

const InformationTab: FC<InformationTabProps> = ({
  categories,
  municipalities,
  subValidate
}) => {
  const { t } = useTranslation();
  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Grid>
        <Grid.Col xs={12}>
          <TranslatableFields
            subValidate={subValidate}
            fields={[
              {
                label: 'NAME',
                name: 'name',
                type: 'text',
                required: true
              },
              {
                label: 'DESCRIPTION',
                name: 'description',
                type: 'textarea',
                required: true
              },
              {
                label: 'SCHEDULE',
                name: 'schedule',
                type: 'richtext',
                required: true
              }
            ]}
          />
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="category">
            {(props) => (
              <SelectInput
                {...props}
                label={t('CATEGORY')}
                placeholder={t('INPUT_CATEGORY_PLACEHOLDER')}
                data={categories}
                translate={(value) => ActiveTranslation({ value })}
                withAsterisk
              />
            )}
          </Field>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Field name="municipality">
            {(props) => (
              <SelectInput
                {...props}
                label={t('MUNICIPALITY')}
                placeholder={t('INPUT_MUNICIPALITY_PLACEHOLDER')}
                data={municipalities}
                searchable
              />
            )}
          </Field>
        </Grid.Col>
      </Grid>
    </Block>
  );
};

export default InformationTab;
