import { Block } from '@codepoint-pt/xela';
import { Field } from 'react-final-form';
import BlockInput from '../components/contact/BlockInput';

const ContactsTab = () => {
  return (
    <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
      <Field name="contacts">{(props) => <BlockInput {...props} />}</Field>
    </Block>
  );
};

export default ContactsTab;
