import { Block, Grid } from '@codepoint-pt/xela';
import TranslatableFields from '../../../components/inputs/TranslatableFields';

interface Props {
  subValidate: (fields: string[]) => boolean;
}

const TipsTab = ({ subValidate }: Props) => (
  <Block borderRadius="16px" bg="white" padding="15px" margin="10px 0">
    <Grid>
      <Grid.Col xs={12}>
        <TranslatableFields
          subValidate={subValidate}
          fields={[
            {
              label: 'MUST_SEE',
              name: 'recommendations',
              type: 'richtext'
            },
            {
              label: 'TIPS',
              name: 'tips',
              type: 'richtext'
            }
          ]}
        />
      </Grid.Col>
    </Grid>
  </Block>
);

export default TipsTab;
