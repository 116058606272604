import { FC } from 'react';
import { SelectInput, SelectInputProps } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { AnyObject } from '../../models/Generic';

const translateToString = (value: AnyObject, t: any, i18n: any) => {
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    return value;
  }
  if (!value || typeof value !== 'object') return t('TRANSLATION_NOT_FOUND');
  if (value[i18n.resolvedLanguage] && value[i18n.resolvedLanguage] !== '')
    return value[i18n.resolvedLanguage];
  return t('TRANSLATION_NOT_FOUND');
};

const IntlSelectInput: FC<SelectInputProps> = ({
  keyValue = '_id',
  keyLabel = 'name',
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const data = props.data.map((data) => ({
    ...data,
    value: data[keyValue],
    label: translateToString(data[keyLabel], t, i18n)
  }));
  return <SelectInput {...props} data={data} />;
};

export default IntlSelectInput;
