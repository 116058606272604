/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Field } from 'react-final-form';

const identity = (value: any) => value;

interface ParseFieldProps {
  name: string;
  parse?: (v: any) => any;
  children: (props: FieldRenderProps<any, HTMLElement, any>) => ReactNode;
}

const ParseField = ({ name, parse, children }: ParseFieldProps) => {
  return (
    <Field name={name} parse={!!parse ? parse : identity}>
      {(props) => children(props)}
    </Field>
  );
};

export default ParseField;
