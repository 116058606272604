import {
  Modal,
  XelaColor,
  Icon,
  Grid,
  BaseButton,
  DateRangeInput
} from '@codepoint-pt/xela';
import yup from '@codepoint-pt/yup-validations';
import { Field, withTypes } from 'react-final-form';
import { BasicForm } from '../../styles/BasicStyles';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import useSectionValidation from '../../hooks/use-validation/use-section-validation';
import useIntlValidation from '../../hooks/use-validation/use-inlt-validation';
import TranslatableFields from '../inputs/TranslatableFields';
import { Alert } from '../../models/Alert';
import ManageTrailList from '../inputs/ManageTrailList';
import ManagePoiList from '../inputs/ManagePoiList';
import { useEffect, useState } from 'react';
import { AnyObject } from '../../models/Generic';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';

interface Params {
  opened: boolean;
  onClose: (changed?: Alert) => void;
  initialValues?: AnyObject;
}

const { Form } = withTypes<Alert>();

const AlertModal = ({ opened, onClose, initialValues }: Params) => {
  const [trails, setTrails] = useState<AnyObject[]>([]);
  const [pois, setPois] = useState<AnyObject[]>([]);

  const { get: getPois } = useFetch('/pois/dropdown');
  const { get: getTrails } = useFetch('/trails/dropdown');

  useEffect(() => {
    const init = async () => {
      const { data: trailsData, success: trailsSuccess } = await getTrails();
      if (trailsSuccess) setTrails(trailsData);
      const { data: poisData, success: poiSuccess } = await getPois();
      if (poiSuccess) setPois(poisData);
    };
    init();
  }, []);

  const { t } = useTranslation();
  const intlObject = useIntlValidation();
  const validatedSchema = useValidationSchema(
    yup.object({
      title: intlObject({ required: true }),
      dates: yup.array().required().length(2)
    })
  );

  const onSubmit = async (values: Alert) => onClose(values);

  return (
    <Modal
      overlayColor={XelaColor.Gray11}
      padding="xl"
      size="xl"
      centered
      opened={opened}
      onClose={() => onClose()}
      title={t('ALERT')}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      {opened && (
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validatedSchema}
        >
          {({ handleSubmit, errors, submitFailed, submitting }) => {
            return (
              <BasicForm onSubmit={handleSubmit}>
                <Grid>
                  <Grid.Col xs={12}>
                    <TranslatableFields
                      subValidate={(fields) =>
                        useSectionValidation(errors, fields, submitFailed)
                      }
                      fields={[
                        {
                          label: 'TITLE',
                          name: 'title',
                          type: 'text'
                        },
                        {
                          label: 'DESCRIPTION',
                          name: 'description',
                          type: 'richtext'
                        }
                      ]}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Field name="dates">
                      {(props) => (
                        <DateRangeInput
                          {...props}
                          label={t('START_END_DATE')}
                        />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Field name="trails">
                      {(props) => (
                        <ManageTrailList {...props} trails={trails} />
                      )}
                    </Field>
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Field name="pois">
                      {(props) => <ManagePoiList {...props} pois={pois} />}
                    </Field>
                  </Grid.Col>
                  <Grid.Col span={12} style={{ textAlign: 'right' }}>
                    <BaseButton
                      loading={submitting}
                      type="submit"
                      leftIcon={<Icon color="white" icon="essentials_save" />}
                    >
                      {t('SAVE')}
                    </BaseButton>
                  </Grid.Col>
                </Grid>
              </BasicForm>
            );
          }}
        </Form>
      )}
    </Modal>
  );
};

export default AlertModal;
