import { useTranslation } from 'react-i18next';
import { forwardRef, useContext, FC } from 'react';
import { LanguageContext } from '../../Context';
import { Group, SelectInput, Text } from '@codepoint-pt/xela';
import styled from 'styled-components';

const CollapsedContainer = styled.div`
  display: flex;
  height: 36px;
  justify-content: center;
  align-items: center;
`;
const LangIcon = styled.img`
  width: ${({ width }) => width || '24px'};
`;

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  value: string;
  label: string;
  name: string;
  icon: string;
}

export const LangItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, icon, ...others }: ItemProps, ref) => (
    <Group noWrap ref={ref} {...others}>
      <LangIcon src={icon} />
      <Text size="sm">{label}</Text>
    </Group>
  )
);
LangItem.displayName = 'LangItem';

const LanguageSelector: FC<{ collapse?: boolean }> = ({ collapse }) => {
  const { t, i18n } = useTranslation();
  const languages = useContext(LanguageContext);

  if (collapse) {
    const lang = languages.find((l) => l.code == i18n.resolvedLanguage);
    return (
      <CollapsedContainer>
        <LangIcon width="32px" src={lang?.icon} />
      </CollapsedContainer>
    );
  }

  return (
    <SelectInput
      input={{
        value: i18n.resolvedLanguage,
        onChange: (v: string | null) => i18n.changeLanguage(v || undefined)
      }}
      placeholder={t('SELECT_LANGUAGE')}
      data={languages}
      keyValue="code"
      keyLabel="tag"
      itemComponent={LangItem}
      translate={t}
    />
  );
};

export default LanguageSelector;
