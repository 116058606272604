import {
  Avatar,
  HStack,
  Icon,
  Typography,
  XelaColor
} from '@codepoint-pt/xela';
import { Logout, UserWrapper, UserInfoWrapper } from '../MenuStyles';
import { User } from '../../../models/User';
import Placeholder from '../../../assets/images/avatar.png';

interface UserProps {
  isOpen: boolean;
  user: User | undefined;
  handleLogout?: React.MouseEventHandler<HTMLDivElement> | undefined;
  handleProfile?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const UserComponent: React.FC<UserProps> = ({
  isOpen,
  user,
  handleProfile,
  handleLogout
}) => {
  return (
    <HStack alignItems="center" height="auto">
      <UserWrapper spacing="5px" onClick={handleProfile}>
        <Avatar src={user?.photo?.url || Placeholder} />
        <UserInfoWrapper justifyContent="center" isOpen={isOpen}>
          <Typography
            variant="body-small-bold"
            color={XelaColor.Gray2}
            noWrap={true}
          >
            {user?.name || 'Username'}
          </Typography>
          {user?.email && (
            <Typography variant="caption" color={XelaColor.Gray6} noWrap={true}>
              {user.email}
            </Typography>
          )}
        </UserInfoWrapper>
      </UserWrapper>
      {!!handleLogout && (
        <Logout onClick={handleLogout} isOpen={isOpen}>
          <Icon icon="technology_power" />
        </Logout>
      )}
    </HStack>
  );
};

export default UserComponent;
