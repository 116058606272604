const sizes = {
  xs: '480px',
  sm: '768px',
  md: '992px',
  lg: '1280px',
  xl: '1440px',
};

export const breakpoints = {
  xs: `(max-width: ${sizes.xs})`,
  sm: `(max-width: ${sizes.sm})`,
  md: `(max-width: ${sizes.md})`,
  lg: `(max-width: ${sizes.lg})`,
  xl: `(max-width: ${sizes.xl})`
};
