import { useTranslation } from 'react-i18next';
import {
  Block,
  Center,
  DoughnutChart,
  DoughnutDataset,
  HStack,
  Typography,
  VStack,
  XelaColor
} from '@codepoint-pt/xela';
import { Dashboard } from './DashboardPage';

interface Props {
  data?: Dashboard;
}

const ByGender = ({ data }: Props) => {
  const { t } = useTranslation();
  return (
    <Block padding="30px" bg="white" borderRadius="16px">
      <Typography variant="subheadline">{t('USERS_BY_GENDER')}</Typography>
      <HStack spacing="32px">
        <Center style={{ position: 'relative' }}>
          <DoughnutChart
            width="200px"
            cutout={45}
            datasets={[
              new DoughnutDataset(
                'Gender',
                [
                  data?.byGender?.undefined,
                  data?.byGender?.male,
                  data?.byGender?.female,
                  data?.byGender?.other
                ],
                [
                  XelaColor.Gray10,
                  XelaColor.Blue7,
                  XelaColor.Pink8,
                  XelaColor.Yellow5
                ],
                undefined,
                undefined,
                16
              )
            ]}
            labels={[t('UNDEFINED'), t('MALE'), t('FEMALE'), t('OTHER')]}
          />
        </Center>
        <VStack height="auto" spacing="8px">
          <HStack spacing="12px">
            <Block bg={XelaColor.Gray10} padding="15px" borderRadius="4px" />
            <Typography variant="caption">
              {t('UNDEFINED')}: <b>{data?.byGender?.undefined}</b>
            </Typography>
          </HStack>
          <HStack spacing="12px">
            <Block bg={XelaColor.Blue7} padding="15px" borderRadius="4px" />
            <Typography variant="caption">
              {t('MALE')}: <b>{data?.byGender?.male}</b>
            </Typography>
          </HStack>
          <HStack spacing="12px">
            <Block bg={XelaColor.Pink8} padding="15px" borderRadius="4px" />
            <Typography variant="caption">
              {t('FEMALE')}: <b>{data?.byGender?.female}</b>
            </Typography>
          </HStack>
          <HStack spacing="12px">
            <Block bg={XelaColor.Yellow5} padding="15px" borderRadius="4px" />
            <Typography variant="caption">
              {t('OTHER')}: <b>{data?.byGender?.other}</b>
            </Typography>
          </HStack>
        </VStack>
      </HStack>
    </Block>
  );
};

export default ByGender;
