import {
  AnyObject,
  DefaultListPage,
  Grid,
  Icon,
  SearchInput,
  Typography
} from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import ActiveTranslation from '../../components/languages/ActiveTranslation';
import { Program } from '../../models/Program';
import { Trail } from '../../models/Trail';

const ProgramsListPage = DefaultListPage<Program>();

const ProgrmasPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    {
      title: t('NAME'),
      dataIndex: 'name',
      sortable: true,
      render: (value: AnyObject) => <ActiveTranslation value={value} />
    },
    {
      title: t('ROUTE'),
      dataIndex: 'trail',
      sortable: true,
      render: (value: Trail) => (
        <ActiveTranslation value={value?.name || 'N/A'} />
      )
    },
    {
      title: t('DAYS'),
      dataIndex: 'totalDays',
      sortable: true
    },
    {
      title: t('KM'),
      dataIndex: 'totalKm',
      sortable: true
    }
  ];

  const { get } = useFetch('/programs');

  return (
    <ProgramsListPage
      translate={t}
      request={{
        get,
        searchFields: ['name|intl', 'description|intl']
      }}
      storage="PROGRAM_FILTERS"
      header={{
        navigate,
        title: t('PROGRAMS'),
        actions: [
          {
            name: t('ADD_NEW'),
            icon: <Icon icon="basics_add" color="white" />,
            onClick: () => navigate('/programs/new')
          }
        ],
        breadcrumbs: [{ title: t('PROGRAMS'), href: '/programs' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/programs/${row._id}`)
      }}
    />
  );
};

export default ProgrmasPage;
