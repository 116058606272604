import { HStack, XelaColor } from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import { MenuOption, MenuOptionName } from '../MenuStyles';
import { MenuOptionProps } from '../MenuOptions';

interface ItemProps {
  isOpen: boolean;
  option: MenuOptionProps;
}

const Item: React.FC<ItemProps> = ({ isOpen, option }) => {
  const { t } = useTranslation();

  // TODO: MANAGE ACTIVE LINKS WITH GROUPS AND PARENTS

  return (
    <MenuOption to={option.url || '/'} end={option.url === '/'}>
      <HStack alignItems="center">
        {option.icon}
        {isOpen && (
          <MenuOptionName
            color={XelaColor.Gray3}
            variant="body-small-bold"
            isMenu={false}
          >
            {t(option.name || '')}
          </MenuOptionName>
        )}
      </HStack>
    </MenuOption>
  );
};

export default Item;
