import { BaseButton, Grid, TextInput, Typography } from '@codepoint-pt/xela';
import yup from '@codepoint-pt/yup-validations';
import { Field, withTypes } from 'react-final-form';
import { useValidationSchema } from '../../../hooks/use-validation/use-validation-schema';
import { BasicForm } from '../../../styles/BasicStyles';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../AuthLayout';
import useFetch from 'use-http';
import { useState } from 'react';

interface Values {
  email: string;
}

const { Form } = withTypes<Values>();

export const RequestValidation = () => {
  const [finish, setFinish] = useState(false);
  const { post } = useFetch('/auth');
  const { t } = useTranslation();

  const onSubmit = async (values: Values) => {
    const { success } = await post('/validate-account', values);
    if (success) setFinish(true);
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={useValidationSchema(
        yup.object({
          email: yup.string().email().required()
        })
      )}
    >
      {({ handleSubmit, submitting }) => (
        <AuthLayout message={t('REQUEST_NEW_ACCOUNT_VALIDATION')}>
          {finish ? (
            <>
              <Typography variant="body-bold">{t('SUCCESS')}</Typography>
              <Typography variant="body">
                {t('NEW_ACCOUNT_VALIDATION_SUCCESS')}
              </Typography>
            </>
          ) : (
            <BasicForm onSubmit={handleSubmit}>
              <Grid gutter={24}>
                <Grid.Col xs={12}>
                  <Field name="email">
                    {(props) => (
                      <TextInput
                        {...props}
                        label={t('EMAIL')}
                        placeholder={t('EMAIL_ACCOUNT_PLACEHOLDER')}
                        type="email"
                      />
                    )}
                  </Field>
                </Grid.Col>
                <Grid.Col xs={12}>
                  <BaseButton
                    size="md"
                    fullWidth
                    type="submit"
                    loading={submitting}
                  >
                    {t('SEND_EMAIL')}
                  </BaseButton>
                </Grid.Col>
              </Grid>
            </BasicForm>
          )}
        </AuthLayout>
      )}
    </Form>
  );
};

export default RequestValidation;
