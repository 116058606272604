import styled from 'styled-components';
import ActiveTranslation from '../languages/ActiveTranslation';
import { AnyObject } from '../../models/Generic';

interface Props {
  html: string;
}

const Content = styled.div`
  &&& {
    .ql-editor {
      padding: 0;
      margin: 0;
    }
  }
`;

const TextInterpolation: React.FC<Props> = ({ html }) => (
  <Content>
    <div
      className="view ql-editor"
      dangerouslySetInnerHTML={{
        __html: html
      }}
    />
  </Content>
);

interface TProps {
  value: AnyObject | string | undefined;
}

export const TranslatableInterpolation = ({ value }: TProps) => {
  const html = ActiveTranslation({ value });
  return <TextInterpolation html={html} />;
};

export default TextInterpolation;
