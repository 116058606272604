import {
  GenericInput,
  Grid,
  ActionIcon,
  Menu,
  Icon,
  Divider,
  HStack,
  VStack,
  BaseButton
} from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ActiveTranslation from '../languages/ActiveTranslation';
import { TranslatableInterpolation } from '../text/TextInterpolation';
import dayjs from 'dayjs';
import { useState } from 'react';
import AlertModal from '../modals/AlertModal';
import { useFetch } from 'use-http';
import { Alert } from '../../models/Alert';
import EmptyState from '../empty/EmptyState';

const BlockContent = styled.div`
  margin-top: 30px;
  position: relative;
  background-color: #eef2ff;
  padding: 10px;
  border-radius: 16px;
`;
const BlockTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  display: flex;
  margin-bottom: 5px;
  color: #000000;
  align-items: center;
`;

const BlockSection = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
`;

const BlockSectionText = styled.div`
  margin-left: 5px;
  display: flex;
  align-items: center;
`;

const sortByDate = (a: Alert, b: Alert) =>
  dayjs(a.end).isBefore(dayjs(b.end)) ? -1 : 1;

interface AlertsInputProps {
  input: GenericInput<Alert[]>;
  afterChange?: (selected: Alert[]) => void;
  on: 'trails' | 'pois';
  id: string;
}

const AlertsInput = ({ input, on, id }: AlertsInputProps) => {
  const initial: Partial<Alert> = {};
  initial[on] = [id];
  const [modal, setModal] = useState({ open: false, initialValues: initial });
  const { t } = useTranslation();
  const alerts = input?.value || [];

  const { post, put } = useFetch('/alerts');

  const updateList = (alert: Alert) => {
    const newList = alerts.map((a) => a);
    const exists = alert[on].includes(id);
    const found = alerts.findIndex((a) => a._id === alert._id);
    if (found > -1) {
      if (exists) {
        newList.splice(found, 1, alert);
      } else newList.splice(found, 1);
      newList.sort(sortByDate);
    } else {
      if (exists) {
        newList.push(alert);
        newList.sort(sortByDate);
      }
    }
    input.onChange(newList);
  };

  const handleClose = async (values?: Alert) => {
    if (values) {
      const payload = {
        ...values,
        start: values.dates[0],
        end: values.dates[1]
      };
      let result;
      if (values._id) {
        result = await put(`/${values._id}`, payload);
      } else {
        result = await post(payload);
      }
      if (result?.success) updateList(result.data);
    }
    setModal({ open: false, initialValues: initial });
  };

  return (
    <Grid gutter="sm">
      <Grid.Col xs={12}>
        <HStack justifyContent="flex-end">
          <BaseButton
            action="secondary"
            onClick={() => setModal({ open: true, initialValues: initial })}
          >
            {t('ADD_NEW')}
          </BaseButton>
        </HStack>
      </Grid.Col>
      {(!alerts || !alerts.length) && (
        <EmptyState
          icon="basics_exclamationCircle"
          title="NO_ALERTS_CONTENT"
          tag="NO_ALERTS_CONTENT_MESSAGE"
        />
      )}
      {alerts.map((alert, index) => (
        <Grid.Col key={index} xs={12}>
          <BlockContent>
            <Menu trigger="hover" closeOnItemClick position="bottom-end">
              <Menu.Target>
                <ActionIcon
                  variant="light"
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px'
                  }}
                >
                  <Icon icon="interface_moreVertical" size={18} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<Icon icon="essentials_edit" />}
                  onClick={() =>
                    setModal({
                      open: true,
                      initialValues: {
                        ...alerts[index],
                        dates: [
                          new Date(alerts[index].start),
                          new Date(alerts[index].end)
                        ]
                      }
                    })
                  }
                >
                  {t('EDIT')}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
            <BlockTitle>
              <HStack>
                <Icon icon="basics_exclamationCircle" />
                <BlockSectionText>
                  {dayjs(alert.start).format('DD/MM/YYYY')} -{' '}
                  {dayjs(alert.end).format('DD/MM/YYYY')}
                </BlockSectionText>
              </HStack>
            </BlockTitle>
            <Divider />
            <BlockSection>
              <VStack>
                <BlockTitle>
                  <ActiveTranslation value={alert.title} />
                </BlockTitle>
                <BlockSectionText>
                  <TranslatableInterpolation value={alert.description} />
                </BlockSectionText>
              </VStack>
            </BlockSection>
          </BlockContent>
        </Grid.Col>
      ))}
      <AlertModal
        opened={modal.open}
        onClose={handleClose}
        initialValues={modal.initialValues}
      />
    </Grid>
  );
};

export default AlertsInput;
