import { AnyObject } from './Generic';
import EatIcon from '../assets/icons/category_eat.svg';
import SleepIcon from '../assets/icons/category_sleep.svg';
import VisitIcon from '../assets/icons/category_visit.svg';
import OtherIcon from '../assets/icons/category_other.svg';

export type CategoryTag =
  | 'WHERE_TO_SLEEP'
  | 'WHERE_TO_EAT'
  | 'WHAT_TO_VISIT'
  | 'SUPPORT_SERVICES';

export interface Category {
  readonly _id: string;
  name: AnyObject;
  tag: CategoryTag;
  color: string;
}

export const GetCategoryIcon = (category: Category) => {
  if (category && category.tag) {
    switch (category.tag) {
      case 'WHERE_TO_SLEEP':
        return SleepIcon;
      case 'WHERE_TO_EAT':
        return EatIcon;
      case 'WHAT_TO_VISIT':
        return VisitIcon;
      case 'SUPPORT_SERVICES':
        return OtherIcon;
    }
  }

  return OtherIcon;
};
