import { LoadingOverlay, PageHeader, toFormData } from '@codepoint-pt/xela';
import yup, { isMongoId } from '@codepoint-pt/yup-validations';
import { useEffect, useState } from 'react';
import { withTypes } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import useFetch from 'use-http';
import headerActions from '../../components/header/HeaderActions';
import FormPrompt from '../../components/prompt/FormPrompt';
import BaseTabs from '../../components/tabs/BaseTabs';
import useSectionValidation from '../../hooks/use-validation/use-section-validation';
import { useValidationSchema } from '../../hooks/use-validation/use-validation-schema';
import { Program } from '../../models/Program';
import { BasicForm } from '../../styles/BasicStyles';
import ContactsTab from './tabs/ContactsTab';
import InformationTab from './tabs/InformationTab';
import PoisTab from './tabs/PoisTab';
import DaysTab from './tabs/DaysTab';
import useIntlValidation from '../../hooks/use-validation/use-inlt-validation';
import { Trail } from '../../models/Trail';
import TipsTab from './tabs/TipsTab';

const { Form } = withTypes<Program>();

const ManagePragramsPage = () => {
  const [loading, setLoading] = useState(true);
  const [trails, setTrails] = useState<Trail[]>([]);
  const [initialValues, setInitialValues] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const intlObject = useIntlValidation();

  const { get, post, put, del } = useFetch('/programs');
  const { get: getTrails } = useFetch('/trails/dropdown');

  useEffect(() => {
    const init = async () => {
      const { data, success } = await getTrails();
      if (success) setTrails(data);
      if (id) {
        const { data, success } = await get(`/${id}`);
        if (success) setInitialValues({ ...data, trail: data?.trail?._id });
      }
      setLoading(false);
    };
    init();
  }, [id]);

  const onSubmit = async (values: Program) => {
    let success = false;
    const payload = toFormData(values);

    if (id) {
      const result = await put(`/${values._id}`, payload);
      success = result.success;
    } else {
      const result = await post(payload);
      success = result.success;
    }

    if (success) navigate('/programs');
  };

  const onDelete = async () => {
    if (id) {
      setLoading(true);
      const { success } = await del(`/${id}`);
      if (success) navigate('/programs');
      else setLoading(false);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={useValidationSchema(
        yup.object({
          name: intlObject({ required: true }),
          description: intlObject({ required: true, html: true }),
          tips: intlObject({ required: false }),
          recommendations: intlObject({ required: false }),
          trail: isMongoId.required()
        })
      )}
    >
      {({ handleSubmit, submitting, pristine, errors, submitFailed }) => (
        <BasicForm onSubmit={handleSubmit}>
          <LoadingOverlay visible={loading} />
          <FormPrompt
            when={!pristine && !submitting && !loading}
            message={t('PROMPT_MESSAGE')}
          />
          <PageHeader
            navigate={navigate}
            title={t(id ? 'EDIT_PROGRAM' : 'ADD_PROGRAM')}
            onBack={() => navigate('/programs')}
            breadcrumbs={[
              { title: t('PROGRAMS'), href: '/programs' },
              { title: t(id ? 'EDIT' : 'ADD') }
            ]}
            actions={headerActions({
              submitting,
              pristine,
              backLink: '/programs',
              onDelete: id ? onDelete : undefined
            })}
          />
          <BaseTabs
            baseTab="information"
            tabs={[
              {
                value: 'information',
                label: t('INFORMATION'),
                children: (
                  <InformationTab
                    trails={trails}
                    subValidate={(fields) =>
                      useSectionValidation(errors, fields, submitFailed)
                    }
                  />
                ),
                error: useSectionValidation(
                  errors,
                  ['name', 'description'],
                  submitFailed
                )
              },
              {
                value: 'days',
                label: t('DAYS'),
                children: <DaysTab />,
                error: useSectionValidation(errors, ['days'], submitFailed)
              },
              {
                value: 'pois',
                label: t('POIS'),
                children: <PoisTab />,
                error: useSectionValidation(errors, ['pois'], submitFailed)
              },
              {
                value: 'tips',
                label: t('TIPS'),
                children: (
                  <TipsTab
                    subValidate={(fields) =>
                      useSectionValidation(errors, fields, submitFailed)
                    }
                  />
                ),
                error: useSectionValidation(
                  errors,
                  ['tips', 'recommendations'],
                  submitFailed
                )
              },
              {
                value: 'contacts',
                label: t('CONTACTS'),
                children: <ContactsTab />,
                error: useSectionValidation(errors, ['contacts'], submitFailed)
              }
            ]}
          />
        </BasicForm>
      )}
    </Form>
  );
};

export default ManagePragramsPage;
