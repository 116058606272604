import { User } from '../../models/User';
import { useTranslation } from 'react-i18next';
import { DefaultListPage, Grid, Icon, SearchInput } from '@codepoint-pt/xela';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import useFetch from 'use-http';

const AdminListPage = DefaultListPage<User>();

const AdminsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    { title: t('NAME'), dataIndex: 'name', sortable: true },
    {
      title: t('EMAIL'),
      dataIndex: 'email',
      sortable: true
    },
    {
      title: t('LAST_LOGIN'),
      dataIndex: 'latest_login',
      render: (value: string) => value ? dayjs(value).format('DD/MM/YYYY HH:mm') : t('NO_RECORD')
    }
  ];

  const { get } = useFetch('/admins');

  return (
    <AdminListPage
      translate={t}
      request={{
        get,
        searchFields: ['name', 'email']
      }}
      storage="ADMIN_FILTERS"
      header={{
        navigate,
        title: t('ADMINISTRATORS'),
        actions: [
          {
            name: t('ADD_NEW'),
            icon: <Icon icon="basics_add" color="white" />,
            onClick: () => navigate('/admins/new')
          }
        ],
        breadcrumbs: [{ title: t('ADMINISTRATORS'), href: '/admins' }]
      }}
      filters={({ filters, handleChange }) => (
        <Grid gutter={24}>
          <Grid.Col xs={4}>
            <SearchInput
              input={{
                value: filters.search,
                onChange: (v: string | null) => handleChange('search', v, 1000)
              }}
              onSearch={(v) => handleChange('search', v)}
            />
          </Grid.Col>
        </Grid>
      )}
      table={{
        columns,
        onRowClick: (row) => navigate(`/admins/${row._id}`)
      }}
    />
  );
};

export default AdminsPage;
